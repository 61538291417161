<template>
    <base-layout title="职工专属体检管理" :logo="logo">
        <router-view></router-view>
    </base-layout>
</template>

<script>
    import Logo from "@/assets/logo0.png"
    import BaseLayout from "./components/baseLayout"
    export default {
        components: {
            BaseLayout
        },
        data() {
            return {
                logo: Logo
            }
        },
        methods: {
            doLogout() {
                this.$router.push({path: '/'})
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
