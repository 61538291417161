<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>各类套餐占比</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <v-chart ref="chart" class="chart" :option="option" v-loading="loading" />
    </el-card>
</template>

<script>
export default {
    name: "lianhuaTaocanzhanbi",
    data() {
        return {
            loading: false,
            option: {
                grid: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c} ({d}%)'
                },
                series: [
                    {
                        name: '套餐占比',
                        type: 'pie',
                        radius: '75%',
                        data: [
                            { value: 1048, name: 'Search Engine' },
                            { value: 735, name: 'Direct' },
                            { value: 580, name: 'Email' },
                            { value: 484, name: 'Union Ads' },
                            { value: 300, name: 'Video Ads' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/enterprise/lianhua/charts/taocanzhanbi')
            this.option.series[0].data = resp.data
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}
</style>
