const data = {
    form: {
        "one":[
            {title: '其他', field: 'qiTa', span: '12',type:"input"},
            {title: '检查结论', field: 'xiaoJie', span: '24',type:"textarea"}
        ],
        "two":[
            {title: '既往史', field: 'jiWangShi', span: '12',type:"input"},
            {title: '现疾病史', field: 'xianJiBingShi', span: '12',type:"input"},
            {title: '家族遗传史', field: 'jiaZuYiChangShi', span: '12',type:"input"},
        ],
        "three":[
            {title: '米面日摄入量', field: 'mimina', span: '12',type:"input"},
            {title: '肉类日摄入量', field: 'roulei', span: '12',type:"input"},
            {title: '鱼类日摄入量', field: 'yulei', span: '12',type:"input"},
            {title: '蛋类日摄入量', field: 'danlei', span: '12',type:"input"},
            {title: '奶类日摄入量', field: 'nailei', span: '12',type:"input"},
            {title: '豆制品日摄入量', field: 'douzhiping', span: '12',type:"input"},
            {title: '蔬菜日摄入量', field: 'shucai', span: '12',type:"input"},
            {title: '水果日摄入量', field: 'shuiguo', span: '12',type:"input"},
            {title: '水日均摄入量', field: 'shui', span: '12',type:"input"},
        ],
        "four":[
            {title: '每周吃早餐天数', field: 'zaocan', span: '12',type:"input"},
            {title: '每周吃夜宵天数', field: 'yexiao', span: '12',type:"input"},
            {title: '您的饮食偏好', field: 'pianhao', span: '12',type:"input"},
            {title: '饮食不良习惯', field: 'xiguan', span: '12',type:"input"},
        ],
        "five":[
            {title: '每天工作时间', field: 'gongzuo', span: '12',type:"input"},
            {title: '每天坐姿时间', field: 'zuozi', span: '12',type:"input"},
            {title: '每周运动情况', field: 'yundong', span: '12',type:"input"},
            {title: '每周运动时间', field: 'yundongshijian', span: '12',type:"input"},
            {title: '锻炼的强度', field: 'duanlian', span: '12',type:"input"},
        ],
        "six":[
            {title: '吸烟状态', field: 'zaocan', span: '12',type:"input"},
            {title: '每天吸烟支数', field: 'yexiao', span: '12',type:"input"},
            {title: '吸烟的年数', field: 'pianhao', span: '12',type:"input"},
            {title: '每周被动吸烟', field: 'xiguan', span: '12',type:"input"},
        ],
        "seven":[
            {title: '当前饮酒状态', field: 'zaocan', span: '12',type:"input"},
            {title: '饮酒类型', field: 'yexiao', span: '12',type:"input"},
            {title: '每天饮酒量', field: 'pianhao', span: '12',type:"input"},
            {title: '饮酒年数', field: 'xiguan', span: '12',type:"input"},
        ],
        "eight":[
            {title: '重大意外困扰', field: 'zaocan', span: '12',type:"input"},
            {title: '精神压力', field: 'yexiao', span: '12',type:"input"},
            {title: '是否睡眠充足', field: 'pianhao', span: '12',type:"input"},
            {title: '糖皮质激素服用', field: 'xiguan', span: '12',type:"input"},
            {title: '接触到的有害因', field: 'xiguan', span: '12',type:"input"},
        ],
    },
    table: {
        columns: [
            {title: 'id', field: 'id', width: ''},
            {title: '建档/预约时间', field: 'created', width: '150'},
            {title: '名称', field: 'name', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '年龄', field: 'age', width: ''},
            {title: '婚否', field: 'marriage', width: ''},
            {title: '职业', field: 'occupation', width: ''},
            {title: '身份证', field: 'idcard', width: '180'},
            {title: '单位名称', field: 'companyName', width: ''},
            {title: '联系方式', field: 'contactWay', width: '160'},
            {title: '检查科室', field: 'keshi', width: ''},
            {title: '体检项目', field: 'tijianXingmu', width: ''},
            {title: '金额', field: 'money', width: ''},
            {title: '登记人', field: 'registrant', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
