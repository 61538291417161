<template>
    <zk-admin-table title="预约排期" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <!-- <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad"></el-button> -->
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
                <!-- <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button> -->
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form :form="search" @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column align="center" type="selection" width="50"></el-table-column>
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData">
            </data-form>
        </template>
        <template v-slot:download>
            <data-down-load :visible="downloadVisible" :form="search" :total="table.total"
                @close="downloadVisible = false"></data-down-load>
        </template>
    </zk-admin-table>
</template>

<script>
import DataForm from './widgets/dataForm'
import SearchForm from './widgets/searchForm'
import DataDownLoad from './widgets/dataDownLoad'
import data from './data'

export default {
    name: 'enterprise-shihua-scheduling-form',
    path: '/main/enterprise/shihua/scheduling/form',
    components: {
        DataForm,
        SearchForm,
        DataDownLoad
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            searchVisible: true,
            downloadVisible: false,
            search: {},
            form: {},
            isNew: false,
            multipleSelection: []
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.getData()
        },
        doDownLoad() {
            this.downloadVisible = true
        },
        handleAdd() {
            this.form = {
                dates: [],
                times: [
                    { time: '08:00-09:00', count: 100 },
                    { time: '09:00-10:00', count: 100 },
                    { time: '10:00-11:00', count: 100 },
                    { time: '11:00-12:00', count: 100 },
                    { time: '12:00-13:00', count: 50 },
                    { time: '13:00-14:00', count: 50 },
                ]
            }
            this.isNew = true
            this.dialogVisible = true
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleMultiUpdate() {
            if (this.multipleSelection.length !== 1) {
                this.$message.error('请选择一条记录')
                return
            }
            this.handleUpdate(this.multipleSelection[0])
        },
        async handleUpdate(row) {
            this.isNew = false
            var temp = JSON.stringify(row)
            this.form = JSON.parse(temp)
            this.dialogVisible = true
        },
        async handleDelete() {
            this.loading = true
            if (this.multipleSelection.length < 1) {
                this.$message.error('请选择要删除的对象')
                return
            }
            for (const e of this.multipleSelection) {
                await this.$http.delete(`/enterprise/shihua/scheduling/times/${e.id}`)
            }
            this.$message.success('删除成功')
            this.getData()
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                if (this.$route.query.date) this.search.date = this.$route.query.date
                const resp = await this.$http.get(`/enterprise/shihua/scheduling/times?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        if (this.$route.params.date) {
            this.search.daterange = [this.$route.params.date, this.$route.params.date]
        }
        this.getData()
    }
}
</script>

<style scoped></style>