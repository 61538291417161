
<template>
    <zk-admin-table title="角色管理列表" :loading="loading" :table="table" @change="handleTableChange" >
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-if="searchVisible"/>
        </template>
        <el-table-column type="index" label="序号" align="center"
                         :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
                         :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="260" label="操作">
            <template slot-scope="scope">
                <el-button type="warning" plain size="mini" @click="handleUpdate(scope.row)">
                    修 改
                </el-button>
                <el-button type="danger" plain size="mini" @click="handleDel(scope.row)">
                    删 除
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
            <el-dialog
                    title="删除"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">你确定要删除此数据吗？</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">取 消</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">确 定</el-button>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>


<script>
    import data from "./data";
    import SearchForm from "./widgets/searchForm";
    import dataForm from './widgets/dataform'
export default {
    name: 'account-role',
    path: '/main/account/role',
    components: {
        SearchForm,
        dataForm
    },
    data() {

        return{
            searchVisible: true,
            table: data.table,
            search: {},
            loading: false,
            dialogVisible: false,
            delmodel: false,
            form: {}
        }
    },
    methods: {
        handleAdd() {
            this.form = {
                "name": "",
            }
            this.dialogVisible = true
        },
        handleDel(row) {
            this.pk = row.id
            this.delmodel = true
        },
        async delSubmit() {
            try {
                let url = `/admin/role/${this.pk}`
                const resp = await this.$http.delete(url)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.delmodel = false
                    this.$message.success("删除成功！")
                    this.getData()
                }
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleUpdate(row) {
            this.form = row
            this.dialogVisible = true
        },
        getData() {
            this.dialogVisible = false
            this.$http.get(`/admin/role?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
            .then(resp => {
                this.table.data = resp.data
                this.table.total = resp.data.total
            }).catch(err => {
                console.log(err)
                this.$message.error(err.message)
            })
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
    },
    mounted() {
        this.getData()
    }
}
</script>
