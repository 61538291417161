<template>
    <zk-admin-table title="眼科" :loading="loading" :table="table" @change="handleTableChange" @row-click="handleRowChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible" ></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:jibenxinxi>
            <zk-ji-ben-xin-xi  />
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit" >
                    修改
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData" title="眼科"></data-form>
        </template>
    </zk-admin-table>
</template>

<script>
    import dataForm from './widgets/dataform'
    import data from './data'
    import ZkJiBenXinXi from "../../components/zkJiBenXinXi/index";
    export default {
        name: 'yanke-list',
        path: '/main/yanke/index',
        components: {
            ZkJiBenXinXi,
            dataForm,
        },
        data() {
            return {
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                search: {}
            }
        },
        methods: {
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            async handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    const resp = await this.$http.get(`/register/list/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.code === 200) {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            handleRowChange(row, column){
                this.dialogVisible = true
                this.form = row
                console.log(row,column)
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
