const data = {
    form: [
        {title: '听力(右)', field: 'tingLiyou', span: '12',type:"input"},
        {title: '听力(左)', field: 'tingLizuo', span: '12',type:"input"},
        {title: '耳部(右)', field: 'erBuyou', span: '12',type:"input"},
        {title: '耳部(左)', field: 'bierBuzuo', span: '12',type:"input"},
        {title: '鼻部(右)', field: 'biBuyou', span: '12',type:"input"},
        {title: '鼻部(左)', field: 'biBuzuo', span: '12',type:"input"},
        {title: '鼻窦(左)', field: 'bidouzuo', span: '12',type:"input"},
        {title: '鼻窦(右)', field: 'bidouyou', span: '12',type:"input"},
        {title: '咽部', field: 'yanBu', span: '12',type:"input"},
        {title: '喉部', field: 'houBu', span: '12',type:"input"},
        {title: '扁桃体', field: 'biantaoti', span: '12',type:"input"},
        {title: '其他', field: 'qiTa', span: '12',type:"input"},
        {title: '小结', field: 'xiaoJie', span: '24',type:"textarea"},
    ],
    table: {
        columns: [
            {title: 'id', field: 'id', width: ''},
            {title: '建档/预约时间', field: 'created', width: '150'},
            {title: '名称', field: 'name', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '年龄', field: 'age', width: ''},
            {title: '婚否', field: 'marriage', width: ''},
            {title: '职业', field: 'occupation', width: ''},
            {title: '身份证', field: 'idcard', width: '180'},
            {title: '单位名称', field: 'companyName', width: ''},
            {title: '联系方式', field: 'contactWay', width: '160'},
            {title: '检查科室', field: 'keshi', width: ''},
            {title: '体检项目', field: 'tijianXingmu', width: ''},
            {title: '金额', field: 'money', width: ''},
            {title: '登记人', field: 'registrant', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
