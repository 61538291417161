<template>
    <el-dialog :visible="visible" width="400px" @close="handleClose" class="dialog" title="终检结论">
        <el-row class="title-zhonjian">
            <el-tabs type="card" >
                <el-tab-pane label="疾病" >
                    <el-card shadow="never">
                        <div class="height">
                            <div v-for="(item,index) in jibing" :key="index">{{item}}</div>
                        </div>
                    </el-card>
                </el-tab-pane>
                <el-tab-pane label="职业疾病" >
                    <el-card shadow="never">
                        <div class="height"></div>
                    </el-card>
                </el-tab-pane>
            </el-tabs>
            <div class="but">
                <el-button type="warning" size="mini">重新分析诊断</el-button>
                <el-button type="warning" size="mini">重新排序</el-button>
                <el-button size="mini">确 定</el-button>
            </div>
        </el-row>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
        },
        data() {
            return{
                form:{},
                tableData:[],
                jibing:[
                    "1、甲状腺右叶低回声",
                    "2、碳14呼气试验阳性",
                    "3、乙肝表面抗体定量阳性",
                    "4、高脂血症Y",
                    "5、脂肪肝",
                    "6、Y~谷氨酷转酶偏高"
                ]
            }
        },
        methods: {
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/cvs/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .height{
        height: 270px;
    }
    ::v-deep .el-tabs__header{
        margin: 0px;
    }
    ::v-deep title-zhonjian .el-card{
        border-top: 1px solid white;
        border-bottom: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
    }
    .but{
        padding-top: 10px;
    }
</style>
