function VolumeFormatter(row, column) {
    // TODO: 0正在发送，1成功
    return '发送成功'
}

const data = {
    table: {
        columns: [
            {title: '时间', field: 'created', width: ''},
            {title: '姓名', field: 'name', width: ''},
            {title: '手机号', field: 'phone', width: ''},
            {title: '验证码', field: 'code', width: ''},
            {title: '状态', field: 'status', width: '',formatter: VolumeFormatter},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
