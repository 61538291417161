function VolumeFormatter(row, column) {
    const temp = row.date +" "+ row.time
    return temp
}

const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: ''},
            {title: '身份证', field: 'idnum', width: ''},
            {title: '职务', field: 'company', width: ''},
            {title: '联系电话', field: 'phone', width: ''},
            {title: '费用余额', field: 'price', width: ''},
            {title: '状态', field: 'status', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
