function VolumeFormatter(row, column) {
    const temp = row.date +" "+ row.time
    return temp
}

const data = {
    table: {
        columns: [
            {title: '姓名', field: 'name', width: '120'},
            {title: '性别', field: 'age', width: '80'},
            {title: '年龄', field: 'gender', width: '80'},
            {title: '身份证', field: 'idnum', width: '180'},
            {title: '职工类型', field: 'type', width: '100'},
            {title: '联系电话', field: 'contact', width: '120'},
            {title: '费用余额', field: 'fee', width: '120'},
            {title: '单位', field: 'company', width: ''},
            // {title: '状态', field: 'status', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
