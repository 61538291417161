import { render, staticRenderFns } from "./formModel.vue?vue&type=template&id=22f33518&scoped=true&"
import script from "./formModel.vue?vue&type=script&lang=js&"
export * from "./formModel.vue?vue&type=script&lang=js&"
import style0 from "./formModel.vue?vue&type=style&index=0&id=22f33518&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f33518",
  null
  
)

export default component.exports