<template>
    <div style="height: calc(100% - 40px);">
        <div>
            <div style="float:right">
                <el-button type="primary" size="mini" @click="getData">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="退费记录" />
        </div>
        <el-table :data="tableData" stripe border style="width: 100%;margin-top:18px" height="100%" v-loading="loading">
            <el-table-column type="index" label="序号" align="center" width="55" />
            <el-table-column prop="YEAR" label="年份" width="70" />
            <el-table-column prop="CARD" label="用户信息" width="180">
                <template slot-scope="scope">
                    <div>{{ scope.row.NAME }}</div>
                    <small>{{ scope.row.CARD }}</small>
                </template>
            </el-table-column>
            <el-table-column prop="ORDERDATE" label="预约日期" width="120" />
            <el-table-column prop="ITEMGROUPSMONEY" label="预约金额" width="100" />
            <el-table-column prop="REFUNDMONEY" label="退回金额" width="100" />
            <el-table-column prop="REFUNDDATE" label="退费时间" width="170" />
            <el-table-column prop="STATUS" label="状态" width="120" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.STATUS == '0'">未审核</el-tag>
                    <el-tag v-else-if="scope.row.STATUS == '1'" type="success">审核通过</el-tag>
                    <el-tag v-else-if="scope.row.STATUS == '2'" type="danger">审核不通过</el-tag>
                    <el-tag v-else-if="scope.row.STATUS == '3'" type="warning">撤销提交</el-tag>
                    <el-tag v-else type="info">未知 ({{scope.row.STATUS}})</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="REFUNDREASON" label="退费原因" />
            <el-table-column align="center" width="120" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" plain size="mini" @click="handleCancelRefund(scope.row)">
                        撤回退费
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'enterprise-shihua-refund',
    path: '/main/enterprise/shihua/refund',
    data() {
        return {
            loading: false,
            search: {},
            tableData: [],
        }
    },
    methods: {
        async handleCancelRefund(row) {
            const confirm = await this.$confirm(`确认撤退 ${row.CARD} 的退费吗？`, "请确认").catch(() => { })
            if (!confirm) return
            this.loading = true
            this.$http.post(`/enterprise/shihua/appoint/refund/cancel`, { idnum: row.CARD })
                .then(resp => {
                    if (resp.data.status == 'success') {
                        this.getData()
                    }
                    this.$message[resp.data.status](resp.data.msg)
                }).catch(err => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/enterprise/shihua/appoint/refund', { params: this.search })
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                console.log(resp.data.data)
                this.tableData = resp.data.data
                // this.tableData.push({
                //     CARD: '230521198602120041',
                //     ORDERDATE: '2023-09-30',
                //     REFUNDDATE: '2023/9/30 12:31:22',
                //     NAME: '赵洋',
                //     YEAR: '2023',
                //     REFUNDMONEY: '252.60',
                //     ItemGroupsName: '自选套餐',
                //     REFUNDREASON: '身体原因不能体检',
                //     STATUS: '0',
                //     ITEMGROUPSMONEY: '252.60'
                // }, {
                //     CARD: '230521198602120041',
                //     ORDERDATE: '2023-09-30',
                //     REFUNDDATE: '2023/9/30 12:31:22',
                //     NAME: '赵洋',
                //     YEAR: '2023',
                //     REFUNDMONEY: '3500.00',
                //     ItemGroupsName: '自选套餐',
                //     REFUNDREASON: '身体原因不能体检',
                //     STATUS: '1',
                //     ITEMGROUPSMONEY: '3500.00'
                // }, {
                //     CARD: '230521198602120041',
                //     ORDERDATE: '2023-09-30',
                //     REFUNDDATE: '2023/9/30 12:31:22',
                //     NAME: '赵洋',
                //     YEAR: '2023',
                //     REFUNDMONEY: '652.20',
                //     ItemGroupsName: '自选套餐',
                //     REFUNDREASON: '身体原因不能体检',
                //     STATUS: '2',
                //     ITEMGROUPSMONEY: '652.20'
                // }, {
                //     CARD: '230521198602120041',
                //     ORDERDATE: '2023-09-30',
                //     REFUNDDATE: '2023/9/30 12:31:22',
                //     NAME: '赵洋',
                //     YEAR: '2023',
                //     REFUNDMONEY: '2100.00',
                //     ItemGroupsName: '自选套餐',
                //     REFUNDREASON: '身体原因不能体检',
                //     STATUS: '3',
                //     ITEMGROUPSMONEY: '2100.00'
                // })
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
:deep(.el-table .el-table__cell) {
    padding: 2px 0;
}
</style>