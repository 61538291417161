import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import App from './App.vue'
import VueClipboards from 'vue-clipboards'
import components from './components'
import http from './plugins/http.js'
import router from './router/index'
import store from './store/index'
import './plugins/prototype'
import './plugins/echarts'
import './mock'

let pkg = require('../package.json')
import Print from 'vue-print-nb'
Vue.use(Print)
Vue.use(ElementUI);
Vue.use(VueClipboards)
Vue.use(components)

Vue.prototype.$isElectron = window && window.process && window.process.versions && window.process.versions['electron']
Vue.prototype.$pkg = pkg
Vue.prototype.$http = http

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
