const data = {
    form: [
        {title: '省份证号', field: 'sfz', span: '24',type:"input"},
        {title: '体检档案', field: 'tjda', span: '18',type:"input"},
        {title: '口腔修复体', field: 'kouQingXiuFuTi', span: '12',type:"input"},
        {title: '其它牙病', field: 'qiTaYaBing', span: '12',type:"input"},
        {title: '龋齿', field: 'quChi', span: '12',type:"input"},
        {title: '缺失牙', field: 'queShiYa', span: '12',type:"input"},
        {title: '需要拔除牙', field: 'baChuYa', span: '12',type:"input"},
        {title: '牙周治疗', field: 'yaZhouZhiLiao', span: '12',type:"input"},
        {title: '错颌畸形', field: 'cuoHeJiXing', span: '12',type:"input"},
        {title: '牙结石', field: 'yaJieShi', span: '12',type:"input"},
        {title: '其他', field: 'qiTa', span: '24',type:"input"},
        {title: '小结', field: 'xiaoJie', span: '24',type:"textarea"},
    ],
    table: {
        columns: [
            {title: 'id', field: 'id', width: ''},
            {title: '建档/预约时间', field: 'created', width: '150'},
            {title: '名称', field: 'name', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '年龄', field: 'age', width: ''},
            {title: '婚否', field: 'marriage', width: ''},
            {title: '职业', field: 'work', width: ''},
            {title: '身份证', field: 'sfz', width: '180'},
            {title: '单位名称', field: 'company', width: ''},
            {title: '联系方式', field: 'phone', width: '150'},
            {title: '检查科室', field: 'keshi', width: ''},
            {title: '体检项目', field: 'tijianXingmu', width: ''},
            {title: '金额', field: 'fee', width: ''},
            {title: '登记人', field: 'registrant', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
