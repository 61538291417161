<template>
    <el-dialog title="编辑" :visible="visible" width="50%" @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" readonly />
            </el-form-item>
            <el-form-item label="身份证号" prop="idnum">
                <el-input v-model="form.idnum" readonly />
            </el-form-item>
            <el-form-item label="预约日期" prop="date">
                <el-input v-model="form.date" readonly />
            </el-form-item>
            <el-form-item label="预约金额" prop="fee_total">
                <el-input v-model="form.fee_total" readonly />
            </el-form-item>
            <el-form-item label="退费金额" prop="RefundMoney">
                <el-input v-model="form.RefundMoney" />
            </el-form-item>
            <el-form-item label="退费原因" prop="RefundReason">
                <el-input v-model="form.RefundReason" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                idnum: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                date: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                fee_total: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                RefundMoney: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                RefundReason: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        async submit() {
            const valid = await this.$refs['form'].validate().catch(() => false)
            if (!valid) return
            this.loading = true
            this.$http.post(`/enterprise/shihua/appoint/refund`, this.form)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.msg)
                        this.$emit('update')
                        this.$emit('close')
                    }
                    else
                        this.$message.error(resp.data.msg)
                }).catch((err) => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
