const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
