import Mock from '../utils'
const Random = Mock.Random

let gender = ['男', '女']
let marriage = ['是', '否']
let tijianXingmu = ['血常规', '尿常规', '肝功能']


function makeData() {
    let result = []
    for (let i = 0; i < 100; i++) {
        let date = new Date();
        let offset = Random.integer(0, 1000)
        date.setDate(date.getDate() + 0 - offset) // 病例时间

        let obj = {
            id: 10000+i,
            created: Random.fdate(-365, 0, [1,2,3,4,5,6]),
            typeId: Random.integer(1, 6),
            name: Random.cname(),
            sfz: Random.id(),
            age: Random.integer(14, 80),
            gender: Random.pick(gender),
            marriage:Random.pick(marriage),
            work:"学生",
            company:"xx学习",
            phone:"13789876511",
            keshi:"总检室",
            tijianXingmu:Random.pick(tijianXingmu),
            fee:Random.integer(14, 3000),
            registrant:Random.cname(),
            project:[
                {
                    name:"总检室",
                    content:["a.结论"]
                },
                {
                    name:"化验室",
                    content:["a.血常规","b.尿常规","c.血糖","d.血尿酸测定一次","e.肝功能五项","f.胱抑素C","g.多肿瘤标志物芯片测定","h.肿瘤因子(TSGF)","i.乙肝前S1表面抗原","j.乙肝五项定量测定/次(时间分辨法)","k.血脂四项","l.碳14呼气试验"]
                },
                {
                    name:"B超",
                    content:["a.B超（肝、胆、脾、胃）","b.甲状腺B超"]
                },
                {
                    name:"放射科",
                    content:["a.胸透"]
                },
                {
                    name:"心电图",
                    content:["a.常规心电图检查12导","b.心电向量"]
                }
            ]
        }
        result.push(obj)
    }
    return result
}

let data = makeData()

Mock.api('/api/register/list/', 'get', () => {
    return {
        "code": 200,
        "total": data.length,
        "data": data
    }
})

Mock.api('/api/register/(\\d+)', 'get', (req) => {
    let id = req.url.replace(/\/api\/register\/(\d+)/, '$1')
    var idex = data.findIndex(e => e.id == id)
    return {
        "code": 200,
        "data": data[idex]
    }
})
