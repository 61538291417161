<template>
    <div>
        <div>
            <div style="float:right">
                <router-link to="/main/enterprise/shihua/scheduling/form">
                    <el-button type="primary" size="small">排期</el-button>
                </router-link>
            </div>
            <el-page-header @back="$router.go(-1)" content="体检排期表" />
        </div>
        <el-calendar :first-day-of-week="7">
            <template slot="dateCell" slot-scope="{date, data}">
                <div :class="data.isSelected ? 'active' : ''" @click="selectDate(date)" style="height: 100%">
                    {{ data.day.split('-').slice(1).join('-') }}
                    <div v-if="dates[date.format('yyyy-MM-dd')]" class="active">
                        体检人数({{ dates[date.format('yyyy-MM-dd')].sum }})</div>
                    <div v-else></div>
                </div>
            </template>
        </el-calendar>
        <el-dialog :title="form.date" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="机构">
                    <el-checkbox-group v-model="form.hospital">
                        <el-checkbox label="大庆油田总医院"></el-checkbox>
                        <el-checkbox label="大庆市人民医院"></el-checkbox>
                        <el-checkbox label="大庆市龙南医院"></el-checkbox>
                        <el-checkbox label="大庆市第二医院"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="体检项目" prop="packages">
                    <el-checkbox-group v-model="form.packages">
                        <el-checkbox label="综合套餐"></el-checkbox>
                        <el-checkbox label="心脑血管套餐"></el-checkbox>
                        <el-checkbox label="消化肿瘤套餐"></el-checkbox>
                        <el-checkbox label="妇科类套餐"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="人员" prop="packages">
                    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;"
                        height="250" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55" align="center" />
                        <el-table-column prop="name" label="姓名" width="120" />
                        <el-table-column prop="gender" label="性别" align="center" width="100"
                            :filters="[{ text: '男', value: '男' }, { text: '女', value: '女' }]" :filter-method="filterHandler"
                            filter-placement="bottom-end" />
                        <el-table-column prop="age" label="年龄" align="center" width="100" />
                        <el-table-column prop="desc" label="备注" show-overflow-tooltip />
                    </el-table>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'enterprise-shihua-scheduling',
    path: '/main/enterprise/shihua/scheduling',
    data() {
        return {
            dialogVisible: false,
            form: { hospital: [], packages: [], users: [] },
            tableData: [],
            dates: {
                '2023-03-12': {
                    count: 22
                }
            }
        }
    },
    methods: {
        selectDate(e) {
            // let strDay = e.format('yyyy-MM-dd')
            // this.form.date = strDay
            // this.dialogVisible = true
            this.$router.push({name: 'enterprise-shihua-scheduling-form', params: {date: e.format('yyyy-MM-dd')}})
        },
        filterHandler(value, row, column) {
            const property = column['property']
            return row[property] === value
        },
        async submit() {
            console.log(this.form)
            // const resp = await this.$http.post(`/worksheet`, this.form)
            // if (resp.data.state == 200) {
            //     this.$message.success("保存成功")
            //     this.getData()
            // }
            this.dates[this.form.date] = {
                count: this.form.users.length
            }
            this.dialogVisible = false
            this.$forceUpdate()
        },
        handleSelectionChange(val) {
            this.form.users = val
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/enterprise/shihua/scheduling/calendar', { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.dates = resp.data.data
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
:deep(.el-table .el-table__cell) {
    padding: 2px 0;
}
</style>