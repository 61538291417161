<template>
    <div>
        <el-row  type="flex">
            <el-col :span="6" class="content el-card box-card is-never-shadow">
                <el-table
                        :data="tableData"
                        border
                        class="table"
                        size="mini"
                        width="100%"
                        >
                    <el-table-column
                            label="科室名称"
                            type="index"
                            align="center"
                            width="70">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="体检项目"
                            align="center"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            prop="money"
                            align="center"
                            label="检查结果"
                            width="">
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="18" class="text-size content el-card box-card is-never-shadow">
                <div class=" border-b">
                    <span class="padding-right10">检查：1207250004</span>
                    <span class="padding-right10">姓名：张海宇</span>
                    <span class="padding-right10">性别：男</span>
                    <span class="padding-right10">年龄：56岁</span>
                </div>
                <div>
                    <div>检查项目：检查结论</div>
                    <div class="conclusion">B超(肝、胆、脾、肾):脂肪肝<br>
                        胆脾肾声像图未见异常<br>甲状腺右叶低回声2-3个，大者大小约1.1cmx0.6cm</div>
                    <div>
                        <span class="padding-right10">检查科室：B超</span>
                        <span class="padding-right10">检查医生：</span>
                        <span class="padding-right10 text-red">|结论医生：徐凤焕</span>
                    </div>
                </div>
                <div class="tabs">
                    <el-tabs type="card">
                        <el-tab-pane label="检查汇总">
                            <div class="height">
                                1.B超结论:<br>B超(肝、胆、脾、肾):脂肪肝<br>胆脾肾声像图未见异常<br>甲状腺右叶低回声2-3个，大者大小约1.1cmx0.6cm
                                <br>
                                2.放射科结论：<br>胸透未见异常<br>

                                碳14呼气试验偏高:246.00个(dpm/mmol)
                                肝叱玉婺苫斯屠#大豹五项Y谷氨酷转脑酶偏高:5350个 (U/L)
                                (1)
                                血常规口流源性粒细胞比率偏低:46.40比率偏高:46.90个%胞数偏高:4.15个(109/L)
                                Ua3
                                尿常规比重偏低:1.01√
                                血脂四项胆固醇7.30+(mmo1/L)3.60+(mmol/L)5胆固醇度1.644(mnol/L)172.00个(mg/dL)
                                天简惠花偏高，918.23个(mIU/ml)
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="健康促进建议">
                            <div class="height"></div>
                        </el-tab-pane>
                        <el-tab-pane label="职业病建议">
                            <div class="height"></div>
                        </el-tab-pane>
                        <el-tab-pane label="健康处方">
                            <div class="height"></div>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="doctor">
                        <el-checkbox v-model="checked">终检医师：</el-checkbox>
                        <el-select v-model="value" placeholder="" size="mini">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div>
                    <el-button type="primary" @click="fuzheng">复诊</el-button>
                    <el-button type="primary" @click="zhonjian">终检结论</el-button>
                    <el-button type="warning">重新生成健康建议</el-button>
                    <el-button type="warning">结论词</el-button>
                    <el-button type="warning">对比设置</el-button>
                    <el-button >确 定</el-button>
                    <el-button >取 消</el-button>
                </div>
            </el-col>
        </el-row>
        <fuzhen-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></fuzhen-form>
        <zhonjian-form :visible="modelZj" :form="form" @close="modelZj=false" @update="getData"></zhonjian-form>
    </div>
</template>

<script>
    import fuzhenForm from './fuzhen'
    import zhonjianForm from "./zhonjian"
    export default {
        name: 'biopsy-edit',
        path: '/main/biopsy/edit/:id',
        components: {
            fuzhenForm,
            zhonjianForm
        },
        data() {
            return{
                modelZj:false,
                checkList:[],
                costType:[],
                options:[],
                tableData:[
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                ],
                dialogVisible:false
            }
        },
        methods: {
            fuzheng(){
              this.dialogVisible = true
            },
            zhonjian(){
              this.modelZj = true
            },
            async getData() {
                try {
                    let pk = this.$route.params.id
                    if (pk) {
                        const resp = await this.$http.get(`/register/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.form = resp.data.data
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .text-size{
        font-size: 14px;
        color: #606266;
        padding: 10px;
    }
    .padding-right10{
        padding-right: 20px;
    }
    .border-b{
        padding: 10px;
        border-bottom: 1px solid #c0c4cc;
    }
    .border{
        border: 1px solid #c0c4cc;
    }
    .conclusion{
        padding: 5px 10px;
        height: 80px;
    }
    .text-red{
        color: red;
    }
    .content{
        margin: 0 10px;
    }
    .height{
        height: 400px;
    }
    .tabs{
        position: relative;
    }
    .doctor{
        position: absolute;
        top: 6px;
        right: 20px;
    }
    ::v-deep .el-table--border, .el-table--group {
        border: 1px solid white;
    }
    ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
        content: "";
        position: absolute;
        background-color: white;
        z-index: 0;
    }
</style>
