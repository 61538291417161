<template>
    <el-dialog :visible="visible" width="600px" @close="handleClose" class="dialog">
        <el-row class="title">
            <el-col :span="6">
                <el-image src=""></el-image>
            </el-col>
            <el-col :span="18" class="title-text">
                <el-col :span="12">
                    <span>检查ID：</span>
                    <span>1206261051</span>
                </el-col>
                <el-col :span="12">
                    <span>姓名：</span>
                    <span>张海霞</span>
                </el-col>
            </el-col>
        </el-row>
        <el-table
                :data="tableData"
                border
                class="table"
                height="250"
                style="width: 100%">
            <el-table-column
                    label="序号"
                    type="index"
                    align="center"
                    width="50">
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="检查项目名称"
                    align="center"
                    width="220">
            </el-table-column>
            <el-table-column
                    prop="money"
                    align="center"
                    label="金额"
                    width="">
            </el-table-column>
            <el-table-column
                    prop="sampling"
                    align="center"
                    label="是否采样">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.sampling"></el-checkbox>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <div class="btn border-bottom flex">
                <div>
                    <el-button size="mini">所有采样项目</el-button>
                </div>
                <div class="btn-option">
                    <el-button size="mini">全选</el-button>
                    <el-button size="mini">取消</el-button>
                </div>
            </div>
        </el-row>
        <el-row>
            <el-col span="6" v-for="(item ,index) in tableData" :key="index" class="text-center">
                <el-button size="mini">{{item.name}}</el-button>
            </el-col>
        </el-row>
        <el-divider content-position="left">所有检验项目：10项</el-divider>
        <el-row>
            <div class="btn flex align-items">
                <div class="flex align-items">
                    <el-checkbox-group  size="small" v-model="checkList" class="text-group">
                        <el-checkbox label="打印新采样检验申请项目" ></el-checkbox>
                        <el-checkbox label="打印采样检验申请项目"  ></el-checkbox>
                    </el-checkbox-group>
                    <div>
                        <el-button size="small">打印所有申请单</el-button>
                        <el-button size="small">打印当前样本申请单</el-button>
                    </div>
                </div>
                <div>
                    <el-button size="small">确 定</el-button>
                </div>
            </div>

        </el-row>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
        },
        data() {
            return{
                checkList:[],
                costType:[],
                tableData:[
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                ]
            }
        },
        methods: {
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/cvs/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }
    .title{
        display: flex;
        align-items: center;
        padding: 10px;
        .title-text{
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }
    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }
    .flex{
        display: flex;
    }
    .btn{
        justify-content: space-between;
        padding: 10px 0;
    }
    .border-bottom{
        border-bottom: 1px solid #EBEEF5;
    }
    .text-center{
        text-align: center;
        padding: 5px 0;
    }
    .align-items{
        align-items: center;
    }
    .text-group{
        display: grid;
        padding-right: 10px
    }
</style>
