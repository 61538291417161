const data = {
    form: [
        {title: '肝脏硬度', field: 'ganZhangYingDu', span: '12',type:"input"},
        {title: '脂肪衰减', field: 'zhiFangShuaiJian', span: '12',type:"input"},
        {title: '检查结论', field: 'xiaoJie', span: '24',type:"textarea"},
    ],
    table: {
        columns: [
            {title: 'id', field: 'id', width: ''},
            {title: '建档/预约时间', field: 'created', width: '150'},
            {title: '名称', field: 'name', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '年龄', field: 'age', width: ''},
            {title: '婚否', field: 'marriage', width: ''},
            {title: '职业', field: 'occupation', width: ''},
            {title: '身份证', field: 'idcard', width: '180'},
            {title: '单位名称', field: 'companyName', width: ''},
            {title: '联系方式', field: 'contactWay', width: '160'},
            {title: '检查科室', field: 'keshi', width: ''},
            {title: '体检项目', field: 'tijianXingmu', width: ''},
            {title: '金额', field: 'money', width: ''},
            {title: '登记人', field: 'registrant', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
