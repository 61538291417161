import { render, staticRenderFns } from "./lianhuaWanchengdu.vue?vue&type=template&id=bb1e4c60&scoped=true&"
import script from "./lianhuaWanchengdu.vue?vue&type=script&lang=js&"
export * from "./lianhuaWanchengdu.vue?vue&type=script&lang=js&"
import style0 from "./lianhuaWanchengdu.vue?vue&type=style&index=0&id=bb1e4c60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb1e4c60",
  null
  
)

export default component.exports