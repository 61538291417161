
<template>
    <zk-admin-table title="新华电厂人员名单" :loading="loading" :table="table" @change="handleTableChange" >
        <template v-slot:buttons>
            <el-row style="float: right">
                <ButtonImportExpert style="margin-right:10px" @close="getData" action="/api/enterprise/xinhuadianchang/staff/upload"/>
                <el-button size="mini" icon="el-icon-upload" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form style="text-align:left" @search="handleSearch" v-if="searchVisible"/>
        </template>
        <el-table-column type="index" label="序号" align="center"
            :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
        <el-table-column align="left"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
    </zk-admin-table>
</template>


<script>
    import data from "./data";
    import SearchForm from "./widgets/searchForm";
    import ButtonImportExpert from "./widgets/buttonImportXLSX";
export default {
    name: 'enterprise-xinhuadianchang-staff',
    path: '/main/enterprise/xinhuadianchang/staff',
    components: {
        SearchForm,
        ButtonImportExpert
    },
    data() {

        return{
            searchVisible: true,
            table: data.table,
            search:{},
            loading: false
        }
    },
    methods: {
        getData() {
            this.loading = true
            this.$http.get(`/enterprise/xinhuadianchang/staff?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
            .then(resp => {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }).catch(err => {
                console.log(err)
                this.$message.error(err.message)
            }).finally(()=>{
                this.loading = false
            })
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
    },
    mounted() {
        this.getData()
    }
}
</script>
