
<template>
    <zk-admin-table title="短信使用记录" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" />
        </template>
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
    </zk-admin-table>
</template>

<script>
import data from "./data";
import SearchForm from "./widgets/searchForm";
export default {
    name: 'sms-log',
    path: '/main/sms/log',
    components: {
        SearchForm,
    },
    data() {

        return {
            loading: false,
            searchVisible: false,
            table: data.table,
            search: {}
        }
    },
    methods: {
        getData() {
            this.$http.get(`/sms/log?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                .then(resp => {
                    this.table.data = resp.data.records
                    this.table.total = resp.data.total
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
    },
    mounted() {
        this.getData()
    }
}
</script>
