<template>
    <el-dialog :visible="visible" width="800px" @close="handleClose" class="dialog">
        <el-row class="title">
            <div>
                <span>检查ID：</span>
                <span>1206261051</span>
            </div>
            <div>
                <span>姓名：</span>
                <span>张海霞</span>
            </div>
        </el-row>
        <el-divider content-position="left">【检查项目】</el-divider>
        <el-table
                :data="tableData"
                border
                class="table"
                height="180"
                size="mini"
                style="width: 100%">
            <el-table-column
                    label="序号"
                    type="index"
                    align="center"
                    width="50">
            </el-table-column>
            <el-table-column
                    label="代码"
                    type="index"
                    align="center"
                    width="80">
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="检查项目名称"
                    align="center"
                    width="220">
            </el-table-column>
            <el-table-column
                    prop="money"
                    align="center"
                    label="金额"
                    width="">
            </el-table-column>
            <el-table-column
                    prop="money"
                    align="center"
                    label="样本"
                    width="">
            </el-table-column>
            <el-table-column
                    prop="sampling"
                    align="center"
                    label="是否采样">
                <template slot-scope="scope">
                    <i class="el-icon-caret-top"></i>
                </template>
            </el-table-column>
            <el-table-column
                    prop="sampling"
                    align="center"
                    label="检查结果">
                <template slot-scope="scope">
                    <i class="el-icon-star-on"></i>
                </template>
            </el-table-column>
        </el-table>
        <el-divider content-position="left">【检查结果】</el-divider>
        <el-row style="display: flex;justify-content: space-between;align-items: center;padding: 10px">
            <div>
                <span>检查项目：</span>
                <span>血常规</span>
            </div>
            <div>
                <span>样本号：</span>
                <span>KC0106</span>
            </div>
            <div>
                <span>检验时间：</span>
                <span>2012-07-25</span>
            </div>
            <div>
                <span>检验医生：</span>

                <el-button size="small">编辑检验数据</el-button>
            </div>
        </el-row>
        <el-row>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    height="180"
                    size="mini"
                    style="width: 100%">
                <el-table-column
                        label="代码"
                        type="index"
                        align="center"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="项目名称"
                        align="center"
                        width="220">
                </el-table-column>
                <el-table-column
                        prop="sampling"
                        align="center"
                        label="检查结果">
                </el-table-column>
                <el-table-column
                        prop="money"
                        align="center"
                        label="标志"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="money"
                        align="center"
                        label="单位"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="sampling"
                        align="center"
                        label="参考范围">
                </el-table-column>
            </el-table>
            <div style="text-align: center;font-size: 12px;color: #c0c4cc;padding: 5px">
                <span>标志设置：0--正常、1--偏低、2--偏高</span>
                <span>检验结果：F2--阴性  F3--阳性 </span>
            </div>
        </el-row>
        <el-row style="padding: 10px">
            <div>
                <span>检查医生：</span>
                <span style="color: red">杨金新</span>
            </div>
            <div style="float: right">
                <el-button size="small">确 定</el-button>
                <el-button size="small">取 消</el-button>
            </div>
        </el-row>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
        },
        data() {
            return{
                checkList:[],
                costType:[],
                tableData:[
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                ]
            }
        },
        methods: {
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/cvs/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .title{
        display: flex;
        justify-content: space-evenly;
    }
    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }
    .flex{
        display: flex;
    }
    .btn{
        justify-content: space-between;
        padding: 10px 0;
    }
    .border-bottom{
        border-bottom: 1px solid #EBEEF5;
    }
    .text-center{
        text-align: center;
        padding: 5px 0;
    }
    .align-items{
        align-items: center;
    }
    .text-group{
        display: grid;
        padding-right: 10px
    }
</style>
