<template>
    <el-dialog :visible="visible" width="50%" @close="handleClose" class="dialog" :title="title">
        <div style="padding-bottom: 10px">
            <el-divider content-position="left">检查项目</el-divider>
            <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd">创建项目</el-button>
        </div>
        <el-table :data="tableData" border class="table" size="mini" width="100%" height="500px"
            :span-method="objectSpanMethod">
            <el-table-column prop="cat" label="检查项目" align="center">
            </el-table-column>
            <el-table-column prop="name" label="项目内容" align="center">
            </el-table-column>
            <el-table-column prop="content" width="300" label="项目意义" align="center">
            </el-table-column>
            <el-table-column prop="fee" label="石化价格" align="center">
            </el-table-column>
            <el-table-column prop="sort" label="排序" align="center">
            </el-table-column>
            <el-table-column align="center" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                        修改
                    </el-button>
                    <el-button type="text" size="mini" @click="handleDel(scope.row)" icon="el-icon-delete">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="padding: 20px;text-align: right">
            <el-button @click="handleClose" size="small">取 消</el-button>
        </div>
        <el-dialog title="删除" :visible.sync="delmodel" width="30%" append-to-body>
            <span style="text-align: center;">你确定要删除此数据吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delmodel = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="delSubmit" size="mini">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog width="40%" title="添加/修改" :visible.sync="innerVisible" append-to-body>
            <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules">
                <el-form-item label="检查项目：" prop="cat">
                    <el-select v-model="form.cat" filterable allow-create clearable default-first-option style="width: 100%"
                        placeholder="">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目内容：" prop="name">
                    <el-input v-model="form.name">
                    </el-input>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="项目意义：">
                            <el-input v-model="form.content" type="textarea" :rows="4">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="石化价格：" prop="fee">
                            <el-input v-model.number="form.fee">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="排序：">
                            <el-input v-model.number="form.sort">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item style="padding: 20px;text-align: right">
                    <el-button type="primary" @click="onSubmit">确 定</el-button>
                    <el-button @click="innerVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        pk: {
            default: null
        },
        title: {
            default: "操作项目"
        },
    },
    data() {
        return {
            innerVisible: false,
            delmodel: false,
            tableData: [],
            form: {
                "package_id": "",
                "cat": "",
                "name": "",
                "content": "",
                "fee": "",
                "sort": null
            },
            options: [
                {
                    value: '科室检查',
                    label: '科室检查'
                },
                {
                    value: '生化项目',
                    label: '生化项目'
                }, {
                    value: '感染性疾病检测',
                    label: '感染性疾病检测'
                }, {
                    value: '颅脑疾病检测(副主任及以上医师)',
                    label: '颅脑疾病检测(副主任及以上医师)'
                }, {
                    value: '心脏疾病检测',
                    label: '心脏疾病检测'
                }, {
                    value: '肺部疾病检测(副主任及以上医师)',
                    label: '肺部疾病检测(副主任及以上医师)'
                }, {
                    value: '胃部疾病检测',
                    label: '胃部疾病检测'
                }, {
                    value: '荷兰飞利浦重要器官彩超检查(副主任及以上医师)',
                    label: '荷兰飞利浦重要器官彩超检查(副主任及以上医师)'
                }, {
                    value: '颈椎疾病检测(副主任及以上医师',
                    label: '颈椎疾病检测(副主任及以上医师'
                }, {
                    value: '腰椎疾病检测(副主任及以上医师)',
                    label: '腰椎疾病检测(副主任及以上医师)'
                }, {
                    value: '肺部功能检测',
                    label: '肺部功能检测'
                }, {
                    value: '必选项目',
                    label: '必选项目'
                }, {
                    value: '早餐',
                    label: '早餐'
                }, {
                    value: '健康管理',
                    label: '健康管理'
                },
            ],
            rules: {
                name: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                cat: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                fee: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
            },
            xpk: null,
            rowSpanArr: [],
            position: null,
        }
    },
    methods: {
        handleUpdate(row) {
            this.form = row
            this.innerVisible = true
        },
        handleDel(row) {
            this.xpk = row.id
            this.delmodel = true
        },
        async delSubmit() {
            try {
                let url = `/enterprise/lianhua/package/item/${this.xpk}`
                const resp = await this.$http.delete(url)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.delmodel = false
                    this.$message.success("删除成功！")
                    this.getData(this.pk)
                }
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleAdd() {
            this.form = {
                "package_id": "",
                "cat": "",
                "name": "",
                "content": "",
                "fee": "",
                "sort": null
            }
            this.innerVisible = true
        },
        async onSubmit() {
            var va = false
            this.$refs.form.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let url = `/enterprise/lianhua/package/item`
                this.form.package_id = this.pk
                const resp = await this.$http.post(url, this.form)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.innerVisible = false
                    this.getData(this.pk)
                    this.$message.success("提交成功！")
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.$emit('update')
        },
        handleClose() {
            this.$emit('close')
        },
        async getData(pk) {
            await this.$http.get(`/enterprise/lianhua/package/item?page=1&pageSize=200&package_id=${pk}`)
                .then(resp => {
                    this.tableData = resp.data.records
                    this.getRowSpan()
                }).catch(err => {
                    console.log(err)
                })
        },
        // eslint-disable-next-line no-unused-vars
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.rowSpanArr[rowIndex];
                return {
                    rowspan: _row, //行
                    colspan: 1 //列
                };
            }
        },
        getRowSpan() {
            this.rowSpanArr = [];
            this.tableData.forEach((item, index) => {
                if (index == 0) {
                    this.rowSpanArr.push(1);
                    this.position = 0;
                } else {
                    if (this.tableData[index].cat == this.tableData[index - 1].cat) {
                        this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
                        this.rowSpanArr.push(0);
                        this.tableData[index].cat = this.tableData[index - 1].cat;
                    } else {
                        this.rowSpanArr.push(1);
                        this.position = index;
                    }
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.dialog ::v-deep .el-dialog__header {
    text-align: center;
}
</style>
