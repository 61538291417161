<template>
    <el-dialog :visible="visible" width="60%" @close="handleClose" class="dialog" :title="title">
        <div style="padding: 20px">
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    size="mini"
                    :span-method="objectSpanMethod"
                    width="100%"
            >
                <el-table-column
                        prop="cat"
                        label="检查项目"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="项目内容"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="content"
                        width="300"
                        label="项目意义"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="fee"
                        label="石化价格"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="sort"
                        label="排序"
                        align="center"
                >
                </el-table-column>
            </el-table>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            title: {
                default: "详情"
            },
        },
        data() {
            return {
                tableData:[],
                rowSpanArr: [],
                position: null,
            }
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.rowSpanArr[rowIndex];
                    return {
                        rowspan: _row, //行
                        colspan: 1 //列
                    };
                }
            },
            async getData(pk) {
                await this.$http.get(`/enterprise/shihua/package/item?page=1&pageSize=200&package_id=${pk}`)
                    .then(resp => {
                        this.tableData = resp.data.records
                        this.getRowSpan()
                    }).catch(err => {
                        console.log(err)
                    })
            },
            handleClose() {
                this.$emit('close')
            },
            getRowSpan() {
                this.rowSpanArr = [];
                this.tableData.forEach((item, index) => {
                    if (index == 0) {
                        this.rowSpanArr.push(1);
                        this.position = 0;
                    } else {
                        if (this.tableData[index].cat == this.tableData[index - 1].cat) {
                            this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
                            this.rowSpanArr.push(0);
                            this.tableData[index].cat = this.tableData[index - 1].cat;
                        } else {
                            this.rowSpanArr.push(1);
                            this.position = index;
                        }
                    }
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 0px;
    }
    .dialog ::v-deep .el-dialog__header {
        text-align: center;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
