<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="类型">
            <el-select v-model="form.type" placeholder="请选择职工类型" clearable>
                <el-option label="男职工" value="男职工"></el-option>
                <el-option label="女职工" value="女职工"></el-option>
                <el-option label="退休人员" value="退休人员"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="姓名">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
            <el-input v-model.trim="form.idnum" clearable></el-input>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {},
        }
    },
    methods: {
        reset() {

        },
        doSearch() {
            this.$emit('search', this.form)
        }
    }
}
</script>

