<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="范围">
            <el-date-picker v-model="form.daterange" size="small" type="daterange"
                value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: ['form'],
    data() {
        return {
            // form: {}
        }
    }
}
</script>

