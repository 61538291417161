const data = {
    table: {
        columns: [
            {title: '类别', field: 'cat', width: ''},
            {title: '项目名称', field: 'name', width: ''},
            {title: '医生级别', field: 'doctor', width: ''},
            {title: '项目意义', field: 'content', width: ''},
            {title: '价格(元)', field: 'fee', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
