import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        username: '',
        appointTypeList: ['门诊', '体检', '核磁共振', '健康证', '驾驶证']
    },
    mutations: {
        set_username (state, username) {
            localStorage.setItem('username', username)
            state.username = username
        },
        set_token (state, token) {
            localStorage.setItem('token', token)
            state.token = token
        }
    },
    getters: {
        username: state => {
            return state.username || localStorage.getItem('username')
        },
        token: state => {
            return state.token || localStorage.getItem('token')
        }
    }
})

export default store
