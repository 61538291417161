const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: '300'},
            {title: '套餐金额', field: 'fee', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '最大年龄', field: 'maxAge', width: ''},
            {title: '最小年龄', field: 'minAge', width: ''},
            {title: '排序', field: 'sort', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
