<template>
    <div>
        <el-row style="margin:0">
            <el-col :span="6" style="padding:10px">
                <count-card title="炼化总人数" :count="counts.userCount" color="rgb(128, 176, 210)" />
            </el-col>
            <el-col :span="6" style="padding:10px">
                <count-card title="已预约人数" :count="counts.appointCount0" icon="el-icon-s-shop" color="rgb(141, 210, 198)" />
            </el-col>
            <el-col :span="6" style="padding:10px">
                <count-card title="已收费人数" :count="counts.appointCount1" icon="el-icon-s-flag" />
            </el-col>
            <el-col :span="6" style="padding:10px">
                <count-card title="用户取消预约数" :count="counts.appointCount_1" icon="el-icon-s-opportunity"
                    color="rgb(144, 147, 153)" />
            </el-col>
        </el-row>
        <!-- <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div style="padding: 20px">
                    <h1 style="font-size: 200%">健康体检信息系统</h1>
                    <p>统一预约来源，优化预约-就诊过程</p>
                    <p>统一数据管理，院前沟通记录，医生信息管理、排班、数据统计分析、分诊叫号</p>
                    <p>
                        <a href="/docs/index.html" target="_blank">API 接口文档</a>
                    </p>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <img src="../../assets/hero.png" class="hidden-sm-and-down" width="500px" />
            </el-col>
        </el-row> -->
        <el-row>
            <el-col :span="6" style="padding:10px">
                <lianhuaWanchengdu />
            </el-col>
            <el-col :span="18" style="padding:10px">
                <lianhuaYuyuequshi />
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="14" style="padding:10px">
                <lianhuaNianlingxingbie />
            </el-col>
            <el-col :span="10" style="padding:10px">
                <lianhuaTaocanzhanbi />
            </el-col>
        </el-row>
        <div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            counts: {}
        }
    },
    methods: {
        getCount() {
            this.$http.get('/enterprise/lianhua/counts')
                .then(resp => {
                    this.counts = resp.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
        }
    },
    mounted() {
        this.getCount()
    }
}
</script>

<style scoped>
button {
    margin: 20px;
}
</style>
