<template>
    <el-dialog :visible="visible" width="30%" @close="handleClose" class="dialog" :title="title">
        <div style="display: flex;justify-content: center;" id="printTest">
            <div class="text">
                <div class="hospital"><span>{{ form.hospital || '大庆油田总医院' }}</span></div>
                <div><span>时间：</span>{{ form.date }} {{ form.time }}</div>
                <div><span>身份证：</span>{{ form.idnum }}</div>
                <div><span>姓名：</span>{{ form.name }}</div>
                <div><span>单位：</span>{{ form.company || '炼化公司' }}</div>
                <div class="project">
                    <el-row>
                        <el-col :span="24"><span>体检套餐</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">{{ form.package }}</el-col>
                    </el-row>
                </div>
                <div><span>总金额：</span>￥{{ form.fee }}</div>
                <div style="height:100px;line-height:100px; text-align:center;border-bottom:1px solid #eee">1</div>
            </div>
        </div>
        <div style="text-align: right">
            <el-button type="primary" size="small" v-print="'#printTest'" @click="handleClose">打印小票</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        title: {
            default: "打印小票"
        },
        form: {
            default: {}
        }
    },
    data() {
        return {}
    },
    methods: {
        handleClose() {
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.text {
    width: 236px;
    font-size: 15px;
    font-weight: 500;

    span {
        letter-spacing: 2px
    }

    div {
        padding: 1px 0;
    }

    .project {
        text-align: center;
        border-bottom: 1px dashed #909399;
        border-top: 1px dashed #909399;
        margin: 10px 0;
    }
}

.hospital {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

@media print {

    /* 打印页面的样式 */
    #printTest {
        /* 这里是打印页面中 #printTest 元素的样式规则 */
        size: auto;
        // width: 200mm;
        // height: 300mm;
        margin: 50px 0;
        padding: 50px 0;
    }
}
</style>
