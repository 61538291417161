const formatdate = {}
formatdate.formattime = function(){
    var date = new Date();
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    var h = date.getHours()
    var m = date.getMinutes()
    var s = date.getSeconds()
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (h >= 0 && h <= 9) {
        h = "0" + h;
    }
    if (m >= 0 && m <= 9) {
        m = "0" + m;
    }
    if (s >= 0 && s <= 9) {
        s = "0" + s;
    }
    var day = date.getDay();
    var days = ''
    switch (day) {

        case 1:
            days = '星期一';
            break;
        case 2:
            days = '星期二';
            break;
        case 3:
            days = '星期三';
            break;
        case 4:
            days = '星期四';
            break;
        case 5:
            days = '星期五';
            break;
        case 6:
            days = '星期六';
            break;
        case 7:
            days = '星期天';
            break;
    }
    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
        + " " + h + seperator2 + m + seperator2 + s + " " + days;
    return currentdate;
}

formatdate.formatdate = function(date){
    var seperator1 = "-";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    return date.getFullYear() + seperator1 + month + seperator1 + strDate;
}

export default formatdate
