const codeFormatter = (row,column) => {
    const code = row[column.property]
    return 20000+parseInt(code)
}

const data = {
    table: {
        columns: [
            {title: '项目分类', field: 'cat', width: ''},
            {title: '项目代码', field: 'id', width: '', formatter: codeFormatter},
            {title: '项目名称', field: 'name', width: ''},
            // {title: '医生级别', field: 'doctor', width: ''},
            {title: '价格(元)', field: 'fee', width: ''},
            {title: '备注', field: 'content', width: ''},

        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
