<template>
    <div class="dialogC" v-dialogDrag>
        <div class="el-dialog" style="width: 600px;border: 1px solid #DCDFE6;">
            <div class="el-dialog__header">
                <span class="el-dialog__title">项目词</span>
            </div>
            <div class="text-size">项目名称：<span>检查描述</span></div>
            <el-row  type="flex">
                    <el-col :span="8" class=" el-card box-card is-never-shadow" style="margin: 10px;padding: 5px">
                        <el-table
                                :data="tableData"
                                border
                                class="table"
                                size="mini"
                                width="100%"
                        >
                            <el-table-column
                                    label="序号"
                                    type="index"
                                    align="center"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="明细项目名称"
                                    align="center"
                            >
                            </el-table-column>
                        </el-table>
                </el-col>
                <el-col :span="16" class=" el-card box-card is-never-shadow " style="margin: 10px;margin-bottom: 50px">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="padding: 5px">
                        <el-tab-pane label="检查诊断词" name="first" >
                            <div style="height: 350px">
                                <div>【内容】</div>
                                <div style="padding: 5px 0">
                                    <el-input
                                            type="textarea"
                                            :rows="3"
                                            placeholder="请输入内容"
                                            >
                                    </el-input>
                                </div>
                                <div>【词汇列表】</div>
                                <el-table
                                        :data="tableData"
                                        border
                                        class="table"
                                        size="mini"
                                        width="100%"
                                        height="220"
                                >
                                    <el-table-column
                                            label="序号"
                                            type="index"
                                            align="center"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            prop="name"
                                            label="项目词汇"
                                            align="center"
                                            >
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="常用词及符号" name="second">常用词及符号</el-tab-pane>
                    </el-tabs>
                    <div style="position: absolute;bottom: 10px;right: 10px">
                        <el-button type="primary" size="mini">增 加</el-button>
                        <el-button type="warning" size="mini">修 改</el-button>
                        <el-button type="danger" size="mini">删 除</el-button>
                        <el-button  size="mini" @click="Close">关 闭</el-button>
                    </div>
                </el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
    export default {
        props: ["id"],
        data() {
            return {
                activeName:"first",
                tableData:[
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    }
                ],
            }
        },
        methods: {
            Close() {
                this.$emit('close')
            },
        },
    };
</script>
<style scoped>
    .dialogC {
        z-index: 9001;
        position: absolute;
        top: 15vh;
        right:calc(50% - 200px);
    }

    .dialogC /deep/ .el-form-item--mini .el-form-item__content, /deep/ .el-form-item--mini .el-form-item__label {
        line-height: 10px;
    }
    .text-size{
        font-size: 17px;
        padding-top: 10px;
        padding-left: 10px;
    }
</style>
