<template>
    <div style="padding-bottom: 20px">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
                <span>体检基本信息</span>
                <span style="padding-left: 30px">档案：</span>
            </div>
            <el-row>
                <el-col :span="10">
                    <el-row>
                        <el-col :span="8">
                            <span>姓名：</span>
                        </el-col>
                        <el-col :span="8">
                            <span>性别：</span>
                        </el-col>
                        <el-col :span="8">
                            <span>年龄：</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <span>婚否：</span>
                        </el-col>
                        <el-col :span="8">
                            <span>职业：</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <span>单位名称：</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <span>联系方式：</span>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <div style="display: flex;align-items: end">
                        <div class="border">
                            <div class="images">
                                <img class="images" src="../../assets/hero.svg"/>
                            </div>
                        </div>
                        <div>
                            <el-form ref="ruleForm" label-width="60px" size="small" class="demo-ruleForm">
                                <el-form-item label="姓名">
                                    <el-input v-model="form.name" autocomplete="off" style="width: 100%"></el-input>
                                </el-form-item>
                                <el-form-item label="体检号">
                                    <el-input v-model="form.num" autocomplete="off" style="width: 100%"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22" style="margin-left: -15px;display: flex;align-items: center">
                    <div style="width: 90px">
                        <div style="color:blue">【建档日期】</div>
                    </div>
                    <el-col :span="5">
                        <el-divider></el-divider>
                    </el-col>
                    <div style="width: 190px;display: flex;align-items: center" >
<!--                        <span style="color:red">【体检金额】</span>-->
                        <el-divider content-position="left"><span style="color:red">【体检金额】</span></el-divider>
                    </div>
                    <el-col :span="6" style="padding-left: 20px">
                        <el-divider><span style="color:blue"></span></el-divider>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18" style="display: flex;">
                    <div style="width: 80px">检查科室：</div>
                    <el-col  :span="22" >
                        <span style="color: #c38282"></span>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18" style="display: flex;">
                    <div  style="color:red;width: 80px">体检项目：</div>
                    <el-col  :span="22" >
                        <span style="color: seagreen"></span>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22" style="margin-left: -15px;display: flex;align-items: center">
                    <div style="width: 90px" class="zhongjian">
                        <el-divider content-position="left"><div style="color:red"></div></el-divider>
                    </div>
                    <el-col :span="5">
                        <el-divider content-position="left">【体检套餐组合名称】</el-divider>
                    </el-col>
                    <div style="width: 190px">
                        <el-divider ></el-divider>
                    </div>
                    <el-col :span="6"  style="display: flex;align-items: center">
                        <div style="width: 150px" class="zhongjian">
                            <el-divider content-position="left"><div >【报告类型】</div></el-divider>

                        </div>
                        <el-divider content-position="left"></el-divider>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18" style="display: flex;">
                    <div style="width: 80px">弃检项目：</div>
                    <el-col  :span="22" >
                        <span></span>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18" style="display: flex;">
                    <div style="width: 80px">未检科室：</div>
                    <el-col  :span="22" >
                        <span ></span>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18" style="display: flex;">
                    <div style="width: 80px">未检项目：</div>
                    <el-col :span="22" >
                        <span></span>
                    </el-col>
                </el-col>
            </el-row>
        </el-card>

    </div>
</template>

<script>
    export default {
        name: 'zk-ji-ben-xin-xi',
        data() {
            return {
                form: {}
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    .el-row {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .border {
        height: 100px;
        width: 80px;
        padding: 5px;
        border: 1px solid #EBEEF5;

        .images {
            height: 100px;
            width: 80px;
            background-color: #EBEEF5;
        }
    }

    .el-form-item {
        margin-bottom: 10px;
    }

    .el-divider--horizontal {
        margin: 9px 0;
    }

    ::v-deep .el-card__body {
        padding: 10px 20px;
    }

    ::v-deep .el-card__header {
        padding: 10px 20px;
    }

    .box-card {
        font-size: 14px;
        font-weight: 500;
    }

    .el-divider__text {
         padding: 0px;
    }
    .zhongjian ::v-deep .el-divider__text.is-left {
         left: 0px;
        transform: translateY(-50%);
    }
</style>
