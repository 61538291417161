<template>
    <div class="menu-tow">
        <div class="menu-tow-li" v-for="(item, index) in menuTow" :key="index" @click="handleRoute(item)" :class="[item.id=='9'?'border-left':'',item.id=='11'?'border-left':'',item.id=='15'?'border-left':'']">
            <span :class="$route.path == item.path?'text-color':''">{{item.label}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MenuTow',
        data() {
            return {
                oneIndex: null,
                menuTow: [
                    {
                        id: '6',
                        label: '申请登记',
                        path: '/main/register/add',
                    },
                    {
                        id: '7',
                        label: '删除记录',
                        path: '/main/dashboa2rd',
                    },                    {
                        id: '8',
                        label: '检索记录',
                        path: '/main/dashbo3ard',
                    },{
                        id: '9',
                        label: '读卡录入',
                        path: '/main/dashbo4ard',
                    },{
                        id: '10',
                        label: '写卡',
                        path: '/main/dashbo5ard',
                    },{
                        id: '11',
                        label: '打印设置',
                        path: '/main/dashbo5ard',
                    },{
                        id: '12',
                        label: '打印预览',
                        path: '/main/dashbo5ard',
                    },{
                        id: '13',
                        label: '打印',
                        path: '/main/dashbo5ard',
                    },{
                        id: '14',
                        label: '分诊打印',
                        path: '/main/dashbo5ard',
                    }
                ]
            }
        },
        mounted() {
        },
        methods: {
            handleRoute(item) {
                this.oneIndex = item.id
                if (item.id==15 || item.id ==6) {
                    this.$router.push({path:item.path})
                }else {
                    this.$message('功能未开放');
                    return
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .menu-tow{
        display: flex;
        height: 40px;
        align-items: center;
        /*line-height: 50px;*/
    }
    .menu-tow-li{
        padding: 0 15px;
        cursor: pointer;
    }
    .text-color{
        color: yellowgreen;
    }
    .border-left{
        border-left: 1px solid #c0c4cc;
    }
</style>
