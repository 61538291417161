<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="套餐名称" >
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>

