<template>
    <el-dialog :visible="visible" width="800px" @close="handleClose" class="dialog" :title="title">
        <el-row>
            <img src="../../../assets/dt.jpg" width="100%">
        </el-row>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: ""
            },
        },
        data() {
            return{
                checkList:[],
                costType:[],
                tableData:[
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                ]
            }
        },
        methods: {
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/cvs/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }
    .title{
        display: flex;
        align-items: center;
        padding: 10px;
        .title-text{
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }
    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }
    .flex{
        display: flex;
    }
    .btn{
        justify-content: space-between;
        padding: 10px 0;
    }
    .border-bottom{
        border-bottom: 1px solid #EBEEF5;
    }
    .text-center{
        text-align: center;
        padding: 5px 0;
    }
    .align-items{
        align-items: center;
    }
    .text-group{
        display: grid;
        padding-right: 10px
    }
</style>
