<template>
    <el-container>
        <el-header class="header">
            <div class="logo" @click="handleCollapse">
                <img :src="logo" alt="logo">
            </div>
            <div class="head">
                职工专属体检管理
            </div>
            <div class="nav horizontal-menu">
                <menuOne></menuOne>
                <menuTow></menuTow>
            </div>
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="username">
                        {{ $store.getters.username }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="link" to="/main">
                            <el-dropdown-item>首页</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/main/password">
                            <el-dropdown-item>修改密码</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/logout">
                            <el-dropdown-item divided>退出登陆</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <el-container style="height: calc(100vh - 70px);">
            <el-aside width="">
                <el-menu class="el-menu-vertical-demo" noborder router :default-active="$route.path" unique-opened
                    :collapse="isCollapse">
                    <template v-for="(menu, index) in menuItems">
                        <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.path" :key="index">
                            <template slot="title">
                                <i :class="menu.icon"></i>
                                <span slot="title">{{ menu.label }}</span>
                            </template>
                            <el-menu-item v-for="(sb, sid) in menu.children" :key="sid" :index="sb.path">{{ sb.label }}
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-else :index="menu.path" :key="index">
                            <i :class="menu.icon"></i>
                            <span slot="title">{{ menu.label }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Logo from "@/assets/logo0.png"
import menuTow from "./components/menuTow";
import menuOne from "./components/menuOne";
import menudata from "../../data/menu"
export default {
    components: {
        menuTow,
        menuOne
    },
    data() {
        return {
            logo: Logo,
            isCollapse: true,
            menuItems: menudata.menuItems
        }
    },
    methods: {
        handleCollapse() {
            this.isCollapse = !this.isCollapse
        },
        async getData() {
            // const resp = await this.$http.get(`/auth/menu`)
            // this.menuItems = resp.data.data
            if (this.$store.getters.username === 'admin') {
                this.menuItems = menudata.menuItems
            } else {
                this.menuItems = menudata.menuItem
            }
        }
    },
    mounted() {
        this.getData()
        let clientWidth = document.documentElement.clientWidth || document.body.clientHeight;
        if (clientWidth < 768) {//当屏幕小于768时，设置Vuex里的数据为true
            this.isCollapse = true
        } else {//反之，设置Vuex里的数据为false
            this.isCollapse = false
        }
    }
}
</script>
<style>
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* 滚动条有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border-radius: 5px;
}

/* 滚动条滑块(竖向:vertical 横向:horizontal) */
::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: rgba(144, 147, 153, .3);
    border-radius: 5px;
}

/* 滚动条滑块hover */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, .3);
}

/* 同时有垂直和水平滚动条时交汇的部分 */
::-webkit-scrollbar-corner {
    display: block;
    /* 修复交汇时出现的白块 */
}
</style>
<style lang="scss" scoped>
.header {
    background: #2081c0;
    border-bottom: 1px solid #2081c0;
    color: #fff;
    display: flex;
    flex-direction: row;
    height: 70px !important;
    /*line-height: 60px;*/
    align-items: center;
    padding-left: 15px !important;

    .logo {
        vertical-align: middle;
        display: flex;
        align-items: center;
        color: #ffffff;
        text-decoration: none;

        img {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            margin-right: 5px;
        }
    }

    .head {
        width: 150px;
    }

    .nav {
        flex: 1;
        overflow: hidden;
        font-size: 14px;
    }

    .el-menu.el-menu--horizontal {
        /* border-bottom: solid 1px #e6e6e6; */
        border-bottom: none;
    }

    v-deep .el-submenu__title i {
        color: #ffffff;
    }

    .user {
        text-align: right;
        width: 180px;

        .username {
            color: #ffffff;
            cursor: pointer;
            vertical-align: middle;
            display: flex;
            align-items: center;

            .user-avatar {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.link {
    text-decoration: none;
}

.el-aside {
    /*background-color: #eeeeee;*/
    color: #333;
    border-right: 1px solid #e6e6e6;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 100px;
    border: none;
}

el-submenu__icon-arrow::v-deep el-icon-arrow-down::v-deep {
    color: white !important;
}

/*.el-menu-item.is-active::v-deep{*/
/*    color: green;*/
/*    border-bottom-color:red!important;*/
/*    font-size: 16px;*/
/*}*/

/*.el-submenu__title::v-deep {*/
/*    color: green;*/
/*    border-bottom-color:red!important;*/
/*    font-size: 16px;*/
/*}*/
.horizontal-menu {
    padding-left: 5px;
    border-left: 1px solid #c0c4cc;
    /*border-right: 1px solid #c0c4cc;*/
}
</style>
