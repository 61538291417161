import Mock from './utils'
// import './data/user'
import './data/resgter'

Mock.setup({
    timeout: '100-300'
})

// Mock.api('/api/version/gui', 'get', () => {
//     return {
//         "url": "https://mingde.mriabc.com/download/",
//         "version": "1.2.4"
//         }
// })
