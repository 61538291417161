
<template>
    <zk-admin-table title="石化公司预约记录" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <btn-read-id-card @result="readCard" size="mini" />
                <el-button size="mini" icon="el-icon-download" @click="handleExport">导出EXCEL</el-button>
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form :form="search" @search="handleSearch" v-if="searchVisible" />
        </template>
        <el-table-column type="index" label="序号" align="center"
            :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
        <el-table-column label="人员信息" width="180">
            <template slot-scope="scope">
                <div>
                    {{ scope.row.name }}
                </div>
                <small>{{ scope.row.idnum }}</small>
            </template>
        </el-table-column>
        <el-table-column label="预约时间" width="120">
            <template slot-scope="scope">
                <div>
                    {{ scope.row.date }}
                </div>
                <div>{{ scope.row.time }}</div>
            </template>
        </el-table-column>
        <el-table-column label="预约时间" width="150">
            <template slot-scope="scope">
                <div>账户余额：{{ scope.row.price }}</div>
                <div>本次费用：{{ scope.row.fee_total }}</div>
            </template>
        </el-table-column>
        <el-table-column label="自费金额" width="100">
            <template slot-scope="scope">
                <div :style="`color: ${scope.row.fee_zifei > 0 ? 'red' : ''}`">{{ scope.row.fee_zifei }}</div>
            </template>
        </el-table-column>
        <el-table-column label="体检套餐" width="">
            <template slot-scope="scope">
                <div v-if="scope.row.taocan">{{ scope.row.taocan.name }}</div>
                <div v-if="scope.row.zixuan">
                    <el-popover placement="right" title="自选套餐" width="400" trigger="click"
                        v-if="scope.row.zixuan && scope.row.zixuan.length > 0">
                        <el-table :data="scope.row.zixuan" size="small" width="400" height="300">
                            <el-table-column width="" property="name" label="名称"></el-table-column>
                            <el-table-column width="100" property="fee" label="金额"></el-table-column>
                        </el-table>
                        <div style="padding-top:10px;text-align:center">合计金额：{{ scope.row.fee_zixuan }}</div>
                        <el-tag slot="reference" type="warning" size="small" effect="plain">
                            自选套餐
                        </el-tag>
                    </el-popover>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="260" label="操作">
            <template slot-scope="scope">
                <el-button v-if="scope.row.status == 0" type="primary" plain size="mini" @click="handlePay(scope.row)">
                    确认收费
                </el-button>
                <el-button v-if="scope.row.status == 1" type="primary" plain size="mini" @click="handlePrint(scope.row)">
                    打印小票
                </el-button>
                <el-button v-if="scope.row.status == 1" type="danger" plain size="mini" @click="handleRefund(scope.row)">
                    退费
                </el-button>
                <!-- <template v-if="[-1, 1].includes(scope.row.status)">
                    <el-popconfirm title="确定设置此条记录为已预约状态吗？" @confirm="handleReset(scope.row)">
                        <el-button slot="reference" type="danger" plain size="mini"
                            style="margin-left:10px">重置状态</el-button>
                    </el-popconfirm>
                </template> -->
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <print-model :visible="dialogVisible" :form="form" @close="dialogVisible = false" />
            <refund-model :visible="refundVisible" :form="form" @close="refundVisible = false" />
        </template>
    </zk-admin-table>
</template>


<script>
import data from "./data";
import SearchForm from "./widgets/searchForm";
import PrintModel from "./widgets/printModel";
import RefundModel from "./widgets/refundModel";
export default {
    name: 'enterprise-shihua-appoint',
    path: '/main/enterprise/shihua/appoint',
    components: {
        SearchForm,
        PrintModel,
        RefundModel
    },
    data() {
        return {
            dialogVisible: false,
            refundVisible: false,
            searchVisible: true,
            table: data.table,
            search: { idnum: '', status: 0 },
            loading: false,
            form: {},
        }
    },
    methods: {
        readCard(e) {
            if (e.ret == 1) {
                this.search.idnum = e.data.code
                this.getData()
            }
            else
                this.$message.error(e.msg)
        },
        handlePay(row) {
            this.loading = true
            this.$http.post(`/enterprise/shihua/appoint/pay`, row)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.msg)
                        this.handlePrint(row)
                        this.getData()
                    }
                    else
                        this.$message.error(resp.data.msg)
                }).catch((err) => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
        },
        handleRefund(row) {
            this.form = row
            this.refundVisible = true
        },
        handlePrint(row) {
            this.form = row
            this.dialogVisible = true
        },
        async handleExport() {
            try {
                let params = JSON.parse(JSON.stringify(this.search))
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/enterprise/shihua/appoint/records?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        getData() {
            this.loading = true
            this.$http.get(`/enterprise/shihua/appoint/records?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                .then(resp => {
                    this.table.data = resp.data.records
                    this.table.total = resp.data.total
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                    this.loading = false
                })
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
    },
    mounted() {
        this.getData()
    },
    destroyed() {
    }
}
</script>
