<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" :close-on-click-modal="false" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="套餐名称：" prop="name">
                <el-input v-model="form.name">
                </el-input>
            </el-form-item>
            <el-form-item label="套餐金额：" prop="fee">
                <el-input v-model="form.fee">
                </el-input>
            </el-form-item>
            <el-form-item label="性别：">
                <el-checkbox-group v-model="form.gender">
                    <el-checkbox label="男">男</el-checkbox>
                    <el-checkbox label="女">女</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="最大年龄：">
                <el-input v-model.number="form.maxAge">
                </el-input>
            </el-form-item>
            <el-form-item label="最小年龄：">
                <el-input v-model.number="form.minAge">
                </el-input>
            </el-form-item>
            <el-form-item label="排序：">
                <el-input v-model.number="form.sort">
                </el-input>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "添加/修改"
            },
        },
        data() {
            return {
                innerVisible: false,
                rules: {
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    fee: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `/enterprise/shihua/package`
                    const resp = await this.$http.post(url, this.form)
                    if (resp.data.code != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success("提交成功！")
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
