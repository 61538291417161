<template>
    <div>
        <el-row class="content" type="flex">
            <el-col :span="5" class="contents el-card box-card is-always-shadow">
                <div>
                    <div class="clearfix">
                        <div style="padding: 10px">体检项目</div>
                    </div>
                    <div style="padding: 10px 20px">
                        <div v-for="(item,index) in form.project" :key="index" class="padding5">
                            <span class="text-name">{{item.name}}</span>
                            <div v-for="(items,id) in item.content" :key="id">
                                <span style="padding: 3px 0px 3px 20px;font-size: 13px">{{items}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="17">
                <el-card class="box-card">
                    <el-form ref="form" :model="form" label-width="90px" size="mini">
                        <el-row>
                            <el-col :span="18">
                                <el-form-item label="身份证号：" prop="sfz">
                                    <el-input v-model="form.sfz">
                                    </el-input>
                                </el-form-item>
                                <el-row>
                                    <el-col :span="18">
                                        <el-form-item label="体检档案：" prop="gpsInterval">
                                            <el-input v-model="form.tjda" placeholder="">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6" class="text-end">
                                        <el-button size="mini" type="primary" @click="applyRecord">申请/登记档案号</el-button>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="14">
                                        <el-form-item label="姓名：" prop="name">
                                            <el-input v-model="form.name">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" class="text-end">
                                        <el-form-item label="性别：" prop="gender">
                                            <el-radio-group v-model="form.gender">
                                                <el-radio label="男">男</el-radio>
                                                <el-radio label="女">女</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="出生日期：" prop="age">
                                            <div style="display: flex">
                                                <el-input v-model="form.age" placeholder="">岁
                                                </el-input>
                                                <span style="padding-left: 5px">岁</span>
                                            </div>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12" class="text-end">
                                        <el-form-item label-width="30px" prop="birth">
                                            <el-date-picker
                                                    v-model="form.birth"
                                                    style="width: 100%;"
                                                    type="date"
                                                    size="mini"
                                                    placeholder="">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="14">
                                        <el-form-item label="名族：">
                                            <el-select v-model="form.mingzu" placeholder="请选择" style="width: 100%">
                                                <el-option
                                                        v-for="item in mingzuList"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" class="text-end">
                                        <el-form-item label="婚否：" prop="gpsInterval">
                                            <el-radio-group v-model="form.hunyin">
                                                <el-radio label="未婚">未婚</el-radio>
                                                <el-radio label="已婚">已婚</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item label="费别：" prop="feibie">
                                    <el-select v-model="form.feibie" placeholder="请选择" style="width: 100%">
                                        <el-option
                                                v-for="item in feibieList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <div class="text-center padding10">ID: {{form.id}}</div>
                                <div class="img">
                                    <div class="border">
                                        <div class="images">
                                            <img class="images" :src="form.sfzImg"/>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="联系电话：" prop="phone">
                                <el-input v-model="form.phone">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="工作单位：" prop="company">
                                <el-input v-model="form.company" placeholder="">
                                </el-input>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-collapse>
                                <el-collapse-item title="其他填项">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="文化程度：" prop="culture">
                                                <el-select v-model="form.culture" class="formWidth">
                                                    <el-option
                                                            v-for="item in cultureList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="职业：">
                                                <el-select v-model="form.work" class="formWidth">
                                                    <el-option
                                                            v-for="item in workList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="任务计划：" prop="taskPlanning">
                                                <el-select v-model="form.taskPlanning" placeholder="请选择"
                                                           class="formWidth">
                                                    <el-option
                                                            v-for="item in taskList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12" class="text-end">
                                            <el-form-item label="任务分组：">
                                                <el-select v-model="form.taskGroup" placeholder="请选择" class="formWidth">
                                                    <el-option
                                                            v-for="item in taskGroupList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="11">
                                            <el-form-item label="部门岗位：" prop="dep">
                                                <el-select v-model="form.dep" placeholder="请选择" class="formWidth">
                                                    <el-option
                                                            v-for="item in depList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="7">
                                            <el-form-item label="工种：" label-width="70px">
                                                <el-select v-model="form.workType" placeholder="请选择" class="formWidth">
                                                    <el-option
                                                            v-for="item in workTypeList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="工号：" label-width="70px">
                                                <el-input v-model="form.workNum" placeholder="" class="formWidth">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="10">
                                            <el-form-item label="省(市)名：" prop="city">
                                                <el-select v-model="form.city" placeholder="请选择" class="formWidth">
                                                    <el-option
                                                            v-for="item in cityList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="区(县)名：">
                                                <el-select v-model="form.county" placeholder="请选择" class="formWidth">
                                                    <el-option
                                                            v-for="item in countyList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="" label-width="20px">
                                                <el-button size="mini" class="formWidth">附加信息</el-button>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="14">
                                            <el-form-item label="通讯地址：" prop="address">
                                                <el-input v-model="form.address" placeholder="">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="10">
                                            <el-form-item label="申请医师：">
                                                <el-select v-model="form.doctor" placeholder="请选择" class="formWidth">
                                                    <el-option
                                                            v-for="item in doctorList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="14">
                                            <el-form-item label="体检套餐：" prop="gpsInterval">
                                                <el-select v-model="form.taocan" placeholder="请选择" class="formWidth">
                                                    <el-option
                                                            v-for="item in taocanList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="5">
                                            <el-form-item label="" label-width="20px">
                                                <el-button size="mini" class="formWidth">辅助套餐</el-button>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="5">
                                            <el-form-item label="" label-width="20px">
                                                <el-button size="mini" class="formWidth">引用套餐项目</el-button>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="19">
                                            <el-form-item label="备注：">
                                                <el-input v-model="form.remark" placeholder="">
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="5">
                                            <el-form-item label="" label-width="20px">
                                                <el-button size="mini" class="formWidth">接触有害因素信息</el-button>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-collapse-item>
                            </el-collapse>
                        </el-row>
                        <el-row style="padding: 5px 0">
                            <el-button size="mini" type="warning">退项</el-button>
                            <el-button size="mini" type="danger" @click="handleClean">清除</el-button>
                            <el-button size="mini" type="warning">弃项</el-button>
                            <div style="float: right">
                                <el-select
                                        ref="mySelect"
                                        @change="tjProject"
                                        style="padding-right: 10px"
                                        v-model="project"
                                        size="small"
                                        filterable
                                        remote
                                        default-first-option
                                        reserve-keyword
                                        placeholder="请输入关键词"
                                        :remote-method="remoteMethod"
                                        :loading="loading">
                                    <el-option
                                            v-for="(item,index) in projectList"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.name">
                                    </el-option>
                                </el-select>
                                <el-button size="mini" type="primary">待选收费列表</el-button>
                            </div>
                        </el-row>
                        <el-row>
                            <el-divider content-position="left">【已选收费项目】</el-divider>
                            <el-table
                                    :data="form.project"
                                    border
                                    class="table"
                                    height="200"
                                    size="mini"
                            >
                                <el-table-column
                                        label="序号"
                                        type="index"
                                        align="center"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        label="项目代码"
                                        prop="code"
                                        align="center"
                                        width="100">
                                </el-table-column>
                                <el-table-column
                                        prop="name"
                                        label="收费项目名称"
                                        align="center"
                                        width="220">
                                </el-table-column>
                                <el-table-column
                                        prop="money"
                                        align="center"
                                        label="金额"
                                       >
                                </el-table-column>
                                <el-table-column
                                        prop="discount"
                                        align="center"
                                        label="折扣"
                                        >
                                </el-table-column>
                                <el-table-column
                                        prop="way"
                                        align="center"
                                        label="收费方式"
                                        >
                                </el-table-column>
                            </el-table>
                        </el-row>
                        <el-row class="text-size text-w padding5">
                            应收金额：{{form.sumMoney}}元
                        </el-row>
                        <el-row class="text-size text-w padding5">
                            <el-form-item label="折扣：" prop="discount" label-width="55px">
                                <el-input v-model="form.discount" type="number" size="mini"
                                          style="width: 140px" :max="100" :min="0">
                                </el-input>
                                <span style="color: red;padding: 0 10px 0 5px">%</span>
                                折后金额：<span style="color: red">{{form.discountMoney}}元</span>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
            <div class="add">
                <el-button @click="$router.go(-1)" size="small">取 消</el-button>
                <el-button type="primary" size="small" @click="addSubmit">提 交</el-button>
            </div>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'register-edit-add',
        path: ['/main/register/edit/:id', '/main/register/add'],
        data() {
            return {
                loading: false,
                feibieList: [],
                cultureList: [],
                workList: [],
                taskList: [],
                taskGroupList: [],
                depList: [],
                workTypeList: [],
                projectList: [],
                taocanList: [],
                doctorList: [],
                cityList: [],
                countyList: [],
                mingzuList: [],
                form: {
                    tjda: "",
                    discount: 100,
                    project: [],
                    sumMoney: 0.0,
                    discountMoney: 0.0
                },
                project: "",
                flag: false
            }
        },
        methods: {
            handleClean(){
                this.form.project = []
            },
            tjProject(value){
                this.form.project.push({name:value})
                this.project = ""
                this.focusSelect();
            },
            focusSelect() {
                this.projectList = []
                this.$nextTick(() => {
                    const select = this.$refs.mySelect;
                    select.toggleMenu(); // 打开下拉菜单
                    const input = select.$el.querySelector('.el-input__inner');
                    input.focus(); // 将光标聚焦到输入框
                });
            },
            remoteMethod(query) {
                if (query !== '') {
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                        this.projectList = [
                            {
                                name:"血常规",
                            },
                            {
                                name:"尿常规",
                            }
                        ]
                    }, 200);
                } else {
                    this.projectList = [];
                }
            },
            applyRecord() {
                this.form.tjda = "20230311001"
            },
            addSubmit() {
                console.log(this.form)
            },
            async getData() {
                try {
                    let pk = this.$route.params.id
                    if (pk) {
                        const resp = await this.$http.get(`/register/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.form = resp.data.data
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    ::v-deep .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 5px;
    }

    .text-end {
        text-align: end;
    }

    .padding5 {
        padding: 5px 0px;
    }

    .padding10 {
        padding: 10px 0px;
    }

    .text-size {
        font-size: 14px;
        color: #606266;
    }

    .text-center {
        text-align: center;
    }

    .text-w {
        font-weight: bold;
        color: #909399;

        ::v-deep .el-form-item__label {
            color: #909399;
        }
    }

    .img {
        display: flex;
        justify-content: center;
    }

    .content {
        padding-bottom: 20px;
    }

    .border {
        height: 120px;
        width: 100px;
        padding: 5px;
        border: 1px solid #EBEEF5;

        .images {
            height: 120px;
            width: 100px;
            background-color: #EBEEF5;
        }
    }

    .clearfix {
        border-bottom: 1px solid #c0c4cc;
    }

    .text-name {
        font-size: 13px;
        color: #909399;
        font-weight: bold
    }

    ::v-deep .el-radio {
        margin-right: 10px;
    }

    .formWidth {
        width: 100%;
    }

    ::v-deep .el-collapse-item__arrow {
        margin: 0px 8px 0 10px;
    }

    ::v-deep .el-collapse-item__header {
        font-size: 14px;
        color: #3a8ee6;
    }

    ::v-deep .el-collapse-item__header {
        height: 30px;
        line-height: 30px;
    }

    ::v-deep .el-divider--horizontal {
        margin: 13px 0;
    }

    .add {
        position: fixed;
        bottom: 10px;
        left: 230px;
        right: 8%;
        text-align: center;
        background-color: #fff;
        border-radius: 0 !important;
        border: 1px solid #e6e6e6;
        padding: 5px;
        color: #234;
        z-index: 100;
    }

    ::v-deep .el-card__body {
        padding: 10px 20px;
    }

    .contents {
        margin-right: 15px;
    }
</style>
