<template>
    <zk-admin-table title="炼化公司现场登记" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-download" @click="handleReadCard" :loading="loading">读身份证</el-button>
                <el-button size="mini" icon="el-icon-download" @click="handleExport">导出EXCEL</el-button>
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-plus" type="warning" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form :form="search" @search="handleSearch" v-if="searchVisible" />
        </template>
        <el-table-column type="index" label="序号" align="center"
            :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="200" label="操作">
            <template slot-scope="scope">
                <el-button v-if="scope.row.status == 0" type="primary" plain size="mini" @click="handlePay(scope.row)">
                    确认收费
                </el-button>
                <el-button v-if="scope.row.status == 1" type="primary" plain size="mini" @click="handlePrint(scope.row)">
                    打印小票
                </el-button>
                <template v-if="[-1, 1].includes(scope.row.status)">
                    <el-popconfirm title="确定设置此条记录为已预约状态吗？" @confirm="handleReset(scope.row)">
                        <el-button slot="reference" type="danger" plain size="mini"
                            style="margin-left:10px">重置状态</el-button>
                    </el-popconfirm>
                </template>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <print-model :visible="dialogVisible" :form="form" @close="dialogVisible = false" />
            <form-model :visible="formVisible" :form="form" @close="formVisible = false" @update="getData" />
        </template>
    </zk-admin-table>
</template>


<script>
import data from "./data";
import SearchForm from "./widgets/searchForm";
import PrintModel from "./widgets/printModel";
import FormModel from "./widgets/formModel";

export default {
    name: 'enterprise-lianhua-checkin',
    path: '/main/enterprise/lianhua/checkin',
    components: {
        SearchForm,
        PrintModel,
        FormModel
    },
    data() {
        return {
            dialogVisible: false,
            formVisible: false,
            searchVisible: true,
            table: data.table,
            search: { idnum: '', status: 0 },
            loading: false,
            form: { name: '', idnum: '', date: '', time: '', fee: '', package: '' },
            websockets: null
        }
    },
    methods: {
        handleReadCard() {
            if (this.websockets.readyState !== WebSocket.OPEN) {
                return this.$message.error('身份证读卡器未连接')
            }
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 1000)
            try {
                this.websockets.send("04?timeout=10000")
            } catch (err) {
                this.$message.error('身份证读卡器连接失败')
                console.log.error(err.message)
            }
        },
        initReadCard() {
            var url = "ws:127.0.0.1:7415"
            this.websockets = new WebSocket(url);
            this.websockets.onerror = (event) => {
                console.error('WebSocket error:', event);
                // this.$message.error('身份证读卡器连接失败!');
            };
            this.websockets.onopen = (event) => {
                console.log('open', event)
            }
            this.websockets.onclose = (event) => {
                console.log('close', event)
            }
            //监听接收消息的情况
            this.websockets.onmessage = (res) => {
                if (res.data == "failed to obtain ID card information") {
                    // document.querySelector("#message").innerHTML += `<p>接收数据: ${res.data}</p>`
                    return;
                }
                var alldata = res.data.split("|");
                if (alldata.length >= 17) {
                    this.search.idnum = alldata[6]
                    this.getData()
                } else {
                    this.$message.error(res.data)
                }
                // if (alldata.length >= 17) {
                //     document.getElementById("partyLog").value = "读卡成功";
                //     document.getElementById("partyName").value = alldata[1];
                //     document.getElementById("nation").value = alldata[3];
                //     document.getElementById("gender").value = alldata[2];
                //     document.getElementById("certAddress").value = alldata[5];
                //     document.getElementById("bornDay").value = alldata[4];
                //     document.getElementById("certNumber").value = alldata[6];
                //     document.getElementById("certOrg").value = alldata[7];
                //     document.getElementById("effDate").value = alldata[8];
                //     document.getElementById("expDate").value = alldata[9];
                //     document.all['PhotoDisplay'].src = 'data:image/bmp;base64,' + alldata[17];
                // }
                // else {
                //     document.getElementById("partyLog").value = res.data;
                // }
                this.loading = false
            }
        },
        handlePay(row) {
            this.$http.post(`/enterprise/lianhua/appoint/pay`, row)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.msg)
                        this.handlePrint(row)
                        this.getData()
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                }).catch((err) => {
                    this.$message.error(err.message)
                })
        },
        handleReset(row) {
            this.$http.post(`/enterprise/lianhua/appoint/reset`, row)
                .then(resp => {
                    if (resp.data.code == 200)
                        this.$message.success(resp.data.msg)
                    else
                        this.$message.error(resp.data.msg)
                    this.getData()
                }).catch((err) => {
                    this.$message.error(err.message)
                })
        },
        handlePrint(row) {
            this.form = row
            this.dialogVisible = true
        },
        handleAdd() {
            this.form = {
                name: '',
                idnum: '',
                date: new Date().format('yyyy-MM-dd'),
                time: '08:00-09:00',
                fee: '',
                package: '',
            }
            this.formVisible = true
        },
        async handleExport() {
            try {
                let params = JSON.parse(JSON.stringify(this.search))
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/enterprise/lianhua/appoint/checkin?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        async getData() {
            this.loading = true
            await this.$http.get(`/enterprise/lianhua/appoint/checkin?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                .then(resp => {
                    this.table.data = resp.data.records
                    this.table.total = resp.data.total
                }).catch(err => {
                    console.log(err)
                })
            this.loading = false
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
    },
    mounted() {
        this.getData()
        this.initReadCard()
    },
    destroyed() {
        this.websockets.close()
        this.websockets = null
    }
}
</script>
