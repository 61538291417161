<template>
    <div class="menu-one">
        <div class="menu-one-li" v-for="(item, index) in menuOne" :key="index" @click="handleRoute(item)">
            <el-dropdown trigger="click">
                <span style="color: white">
                    {{ item.label }}
                </span>
                <el-dropdown-menu slot="dropdown">
                    <router-link class="link" :to="items.path" v-for="(items, indexs) in item.children" :key="indexs">
                        <el-dropdown-item>{{ items.label }}</el-dropdown-item>
                    </router-link>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="menu-one-li border-left">{{ date }}</div>
        <div>当前版本: {{ $pkg.version }}</div>
    </div>
</template>

<script>
import formatdate from "../../../plugins/formatdate";

export default {
    name: 'MenuOne',
    data() {
        return {
            date: formatdate.formattime(),
            menuOne: [
                {
                    id: '10',
                    label: '系统设置(S)',
                    children: [
                        {
                            id: '10-1',
                            label: '用户管理',
                            path: '/main/account/user',
                        },
                        {
                            id: '10-2',
                            label: '角色管理',
                            path: '/main/account/role',
                        },
                        {
                            id: '10-3',
                            label: '职能科室设置',
                            path: '/main/zk/zhinengkeshi',
                        },
                        {
                            id: '10-4',
                            label: '设备列表设置',
                            path: '/main/zk/shebeiliebiao',
                        },
                        {
                            id: '10-5',
                            label: '体检类型设置',
                            path: '/main/zk/tijianleixing',
                        },
                        {
                            id: '10-5',
                            label: '体检单位设置',
                            path: '/main/zk/tijian/danwei',
                        },
                        {
                            id: '10-5',
                            label: '体检部门/岗位设置',
                            path: '/main/zk/tijianleixing',
                        },
                        {
                            id: '10-5',
                            label: '体检工种设置',
                            path: '/main/zk/tijianleixing',
                        },
                        {
                            id: '10-5',
                            label: '民族设置',
                            path: '/main/zk/tijianleixing',
                        },
                        // {
                        //     id: '10-5',
                        //     label: '功能科室设置',
                        //     path: '/main/zk/tijianleixing',
                        // },
                        // {
                        //     id: '10-5',
                        //     label: '统计科室设置',
                        //     path: '/main/zk/tijianleixing',
                        // },
                        {
                            id: '10-5',
                            label: '收费方式设置',
                            path: '/main/zk/tijianleixing',
                        },
                        {
                            id: '10-5',
                            label: '检验样本分类设置',
                            path: '/main/zk/tijianleixing',
                        },
                        {
                            id: '10-5',
                            label: '检验报告输出分类设置',
                            path: '/main/zk/tijianleixing',
                        },
                        {
                            id: '10-5',
                            label: '收费项目设置',
                            path: '/main/zk/tijianleixing',
                        },
                        {
                            id: '10-5',
                            label: '物价项目设置',
                            path: '/main/zk/tijianleixing',
                        },
                    ]
                },
                {
                    id: '20',
                    label: '检查科室(C)',
                }, {
                    id: '30',
                    label: '综合应用(T)',
                }, {
                    id: '40',
                    label: '帮助(H)',
                }
            ],
        }
    },
    mounted() {
        var that = this
        setInterval(() => {
            //需要定时执行的代码
            that.date = formatdate.formattime();
        }, 1000)
    },
    methods: {
        handleRoute(item) {
            if (item.id != 10) {
                this.$message('功能未开放');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.menu-one {
    display: flex;
    height: 30px;
    align-items: center;
    /*border-bottom: 1px solid #c0c4cc;*/
}

.menu-one-li {
    padding: 0 15px;
    cursor: pointer;
}

.border-left {
    border-left: 1px solid #c0c4cc;
}

.link {
    text-decoration: none;
}</style>
