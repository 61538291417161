const data = {
    form: [
        {title: '辩色力', field: 'bianSeli', span: '12',type:"input"},
        {title: '眼压(左)', field: 'yanYazuo', span: '12',type:"input"},
        {title: '视力(右)', field: 'shiLiyou', span: '12',type:"input"},
        {title: '视力(左)', field: 'shiLizuo', span: '12',type:"input"},
        {title: '眼底(右)', field: 'yanDiyou', span: '12',type:"input"},
        {title: '眼底(左)', field: 'yanDizuo', span: '12',type:"input"},
        {title: '眼压(右)', field: 'yanYayou', span: '12',type:"input"},
        {title: '其他', field: 'qiTa', span: '12',type:"input"},
        {title: '小结', field: 'xiaoJie', span: '24',type:"textarea"},
    ],
    table: {
        columns: [
            {title: 'id', field: 'id', width: ''},
            {title: '建档/预约时间', field: 'created', width: '150'},
            {title: '名称', field: 'name', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '年龄', field: 'age', width: ''},
            {title: '婚否', field: 'marriage', width: ''},
            {title: '职业', field: 'occupation', width: ''},
            {title: '身份证', field: 'idcard', width: '180'},
            {title: '单位名称', field: 'companyName', width: ''},
            {title: '联系方式', field: 'contactWay', width: '160'},
            {title: '检查科室', field: 'keshi', width: ''},
            {title: '体检项目', field: 'tijianXingmu', width: ''},
            {title: '金额', field: 'money', width: ''},
            {title: '登记人', field: 'registrant', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
