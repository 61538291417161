<template>
    <zk-admin-table title="自选套餐" :loading="loading" :table="table" @change="handleTableChange" :rowSpanArr="rowSpanArr">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch"  v-if="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="240" label="操作" >
            <template slot-scope="scope">
                <el-button type="warning" size="mini" plain @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    修改
                </el-button>
                <el-button type="danger" size="mini" plain @click="handleDel(scope.row)" icon="el-icon-delete">
                    删除
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
            <el-dialog
                    title="删除"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">你确定要删除此数据吗？</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">取 消</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">确 定</el-button>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
    import data from "./data";
    import SearchForm from './widgets/searchForm'
    import dataForm from './widgets/dataform'

    export default {
        name: 'shihua-package-zixuan',
        path: '/main/enterprise/shihua/package-custom',
        components: {
            SearchForm,
            dataForm
        },
        data() {
            return {
                loading: false,
                delmodel: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                rowSpanArr: [],
                position: null,
                search: {},
                pk:null
            }
        },
        methods: {
            handleAdd() {
                this.form = {
                    "cat": "",
                    "name": "",
                    "doctor": "",
                    "content": "",
                    "fee": "",
                    "sort": null
                }
                this.dialogVisible = true
            },
            handleDel(row) {
                this.pk = row.id
                this.delmodel = true
            },
            async delSubmit() {
                try {
                    let url = `/enterprise/shihua/package/custom/${this.pk}`
                    const resp = await this.$http.delete(url)
                    if (resp.data.code != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.delmodel = false
                        this.$message.success("删除成功！")
                        this.getData()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                await this.$http.get(`/enterprise/shihua/package/custom?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    .then(resp => {
                        this.table.data = resp.data.records
                        this.table.total = resp.data.total
                        this.getRowSpan()
                        this.loading = false
                    }).catch(err => {
                        this.loading = false
                        console.log(err)
                    })
            },
            getRowSpan() {
                this.rowSpanArr = [];
                this.table.data.forEach((item, index) => {
                    if (index == 0) {
                        this.rowSpanArr.push(1);
                        this.position = 0;
                    } else {
                        if (this.table.data[index].cat == this.table.data[index - 1].cat) {
                            this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
                            this.rowSpanArr.push(0);
                            this.table.data[index].cat = this.table.data[index - 1].cat;
                        } else {
                            this.rowSpanArr.push(1);
                            this.position = index;
                        }
                    }
                });
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>


<style lang="scss" scoped>
    .page-main {
        flex: 1;
        min-height: 100px;
    }
</style>
