const data = {
    menuItems: [
        {
            id: '10',
            icon: "el-icon-office-building",
            label: '登记室',
            path: '/main/register/index',
        },
        // {
        //     id: '10-1',
        //     icon: "el-icon-office-building",
        //     label: '职工体检',
        //     path: '/main/enterprise',
        //     children: [{
        //         id: '10-1-1',
        //         label: '炼化公司',
        //         path: '/main/enterprise/lianhua',
        //     }, {
        //         id: '10-1-2',
        //         label: '石化公司',
        //         path: '/main/enterprise/shihua',
        //     }]
        // },
        // {
        //     id: '10-2',
        //     icon: "el-icon-office-building",
        //     label: '职业套餐设置',
        //     path: '/main/package/index',
        //     children: [{
        //         id: '10-2-1',
        //         label: '固定套餐',
        //         path: '/main/package/guding',
        //     }, {
        //         id: '10-2-2',
        //         label: '自选套餐',
        //         path: '/main/package/zixuan',
        //     }]
        // },
        {
            id: '20',
            icon: "el-icon-office-building",
            label: '采血室',
            path: '/main/blood/index',
        },
        {
            id: '30',
            icon: "el-icon-office-building",
            label: '总检室',
            path: '/main/biopsy/index',
        },
        {
            id: '40',
            icon: "el-icon-office-building",
            label: '化验室',
            path: '/main/laboratory/index',
        },
        {
            id: '50',
            icon: "el-icon-office-building",
            label: 'B超',
            path: '/main/bchao/index',
        },
        {
            id: '60',
            icon: "el-icon-office-building",
            label: '放射科',
            path: '/main/fangshe/index',
        },
        {
            id: '70',
            icon: "el-icon-office-building",
            label: '核磁共振',
            path: '/main/mri/index',
        },
        {
            id: '80',
            icon: "el-icon-office-building",
            label: 'CT室',
            path: '/main/ct/index',
        },
        {
            id: '90',
            icon: "el-icon-office-building",
            label: 'PET-CT室',
            path: '/main/petct/index',
        },
        {
            id: '100',
            icon: "el-icon-office-building",
            label: '多普勒及心动超声',
            path: '/main/doppler/index',
        },
        {
            id: '110',
            icon: "el-icon-office-building",
            label: '妇科',
            path: '/main/fuke/index',
        },
        {
            id: '120',
            icon: "el-icon-office-building",
            label: '胃肠镜室',
            path: '/main/weichangjing/index',
        },
        {
            id: '130',
            icon: "el-icon-office-building",
            label: '病理科',
            path: '/main/bingli/index',
        },
        {
            id: '140',
            icon: "el-icon-office-building",
            label: '心电图室',
            path: '/main/xindiantu/index',
        },
        {
            id: '150',
            icon: "el-icon-office-building",
            label: '一般检查',
            path: '/main/jiancha/index',
        },
        {
            id: '160',
            icon: "el-icon-office-building",
            label: '内科',
            path: '/main/neike/index',
        },
        {
            id: '170',
            icon: "el-icon-office-building",
            label: '神经内科',
            path: '/main/shenjingnk/index',
        },
        {
            id: '180',
            icon: "el-icon-office-building",
            label: '外科',
            path: '/main/waike/index',
        },
        {
            id: '190',
            icon: "el-icon-office-building",
            label: '眼科',
            path: '/main/yanke/index',
        },
        {
            id: '200',
            icon: "el-icon-office-building",
            label: '耳鼻喉科',
            path: '/main/erbihouke/index',
        },
        {
            id: '210',
            icon: "el-icon-office-building",
            label: '口腔科',
            path: '/main/kouqiangke/index',
        },
        {
            id: '220',
            icon: "el-icon-office-building",
            label: '皮肤科',
            path: '/main/pifuke/index',
        },
        {
            id: '230',
            icon: "el-icon-office-building",
            label: '动态心电',
            path: '/main/xindian/index',
        },
        {
            id: '240',
            icon: "el-icon-office-building",
            label: '肺功能',
            path: '/main/feigongneng/index',
        },
        {
            id: '250',
            icon: "el-icon-office-building",
            label: '乳腺扫描',
            path: '/main/ruxian/index',
        },
        {
            id: '260',
            icon: "el-icon-office-building",
            label: '钡餐室',
            path: '/main/beican/index',
        },
        {
            id: '270',
            icon: "el-icon-office-building",
            label: '骨密度室',
            path: '/main/gumidu/index',
        },
        {
            id: '280',
            icon: "el-icon-office-building",
            label: '动脉硬化监控',
            path: '/main/dongmai/index',
        },
        {
            id: '290',
            icon: "el-icon-office-building",
            label: '肝硬化',
            path: '/main/ganyinghua/index',
        },
        {
            id: '300',
            icon: "el-icon-office-building",
            label: '脑电室',
            path: '/main/naodian/index',
        },
        {
            id: '310',
            icon: "el-icon-office-building",
            label: '问诊',
            path: '/main/wenzhen/index',
        },
        {
            id: '320',
            icon: "el-icon-office-building",
            label: '多普勒',
            path: '/main/duopule/index',
        },
        {
            id: '330',
            icon: "el-icon-office-building",
            label: 'C14',
            path: '/main/c14/index',
        },
        {
            id: '340',
            icon: "el-icon-office-building",
            label: '营养早点',
            path: '/main/yingyang/index',
        },
        {
            id: '350',
            icon: "el-icon-office-building",
            label: '人体成分',
            path: '/main/renti/index',
        },
        {
            id: '360',
            icon: "el-icon-office-building",
            label: '糖尿病早期',
            path: '/main/tangniao/index',
        },
        {
            id: '370',
            icon: "el-icon-office-building",
            label: '驾驶员体检',
            path: '/main/jiashiyuan/index',
        },
        {
            id: '380',
            icon: "el-icon-office-building",
            label: '人体抗组试',
            path: '/main/kangzu/index',
        },
        {
            id: '390',
            icon: "el-icon-office-building",
            label: 'HRA健康风',
            path: '/main/hra/index',
        },
        {
            id: '400',
            icon: "el-icon-office-building",
            label: '动态血压',
            path: '/main/xueya/index',
        },
        {
            id: '410',
            icon: "el-icon-office-building",
            label: '电测听',
            path: '/main/dianceting/index',
        },
        {
            id: '420',
            icon: "el-icon-office-building",
            label: '动态心电',
            path: '/main/dongtaixindian/index',
        },
    ],
    menuItem: [
        {
            id: '10',
            icon: "el-icon-s-home",
            label: '系统首页',
            path: '/main/dashboard',
        },
        {
            id: '20',
            icon: "el-icon-s-claim",
            label: '炼化职工体检',
            path: '/main/enterprise/lianhua',
            children: [{
                id: '20-1',
                label: '预约记录',
                path: '/main/enterprise/lianhua/appoint',
            }, {
                id: '20-2',
                label: '现场登记',
                path: '/main/enterprise/lianhua/checkin',
            }, {
                id: '20-3',
                label: '体检排期',
                path: '/main/enterprise/lianhua/scheduling',
            }, {
                id: '20-3',
                label: '固定套餐配置',
                path: '/main/enterprise/lianhua/package',
            // }, {
            //     id: '20-4',
            //     label: '自选套餐配置',
            //     path: '/main/enterprise/lianhua/package-custom',
            }, {
                id: '20-5',
                label: '体检人员名单',
                path: '/main/enterprise/lianhua/staff',
            }]
        },
        {
            id: '30',
            icon: "el-icon-s-claim",
            label: '石化职工体检',
            path: '/main/enterprise/shihua',
            children: [{
                id: '30-1',
                label: '预约记录',
                path: '/main/enterprise/shihua/appoint',
            }, {
                id: '30-2',
                label: '体检排期',
                path: '/main/enterprise/shihua/scheduling',
            }, {
                id: '30-3',
                label: '固定套餐配置',
                path: '/main/enterprise/shihua/package',
            }, {
                id: '30-4',
                label: '自选套餐配置',
                path: '/main/enterprise/shihua/package-custom',
            }, {
                id: '30-5',
                label: '体检人员名单',
                path: '/main/enterprise/shihua/staff',
            }, {
                id: '30-6',
                label: '退费记录',
                path: '/main/enterprise/shihua/refund',
            }, {
                id: '30-7',
                label: '问卷记录',
                path: '/main/enterprise/shihua/evaluate',
            }]
        },
        {
            id: '40',
            icon: "el-icon-s-claim",
            label: '新华电厂体检',
            path: '/main/enterprise/xinhuadianchang',
            children: [{
                id: '40-1',
                label: '预约记录',
                path: '/main/enterprise/xinhuadianchang/appoint',
            // }, {
            //     id: '40-2',
            //     label: '现场登记',
            //     path: '/main/enterprise/xinhuadianchang/checkin',
            }, {
                id: '40-3',
                label: '体检排期',
                path: '/main/enterprise/xinhuadianchang/scheduling',
            // }, {
            //     id: '20-3',
            //     label: '固定套餐配置',
            //     path: '/main/enterprise/lianhua/package',
            // }, {
            //     id: '20-4',
            //     label: '自选套餐配置',
            //     path: '/main/enterprise/lianhua/package-custom',
            }, {
                id: '40-5',
                label: '体检人员名单',
                path: '/main/enterprise/xinhuadianchang/staff',
            }]
        },
        {
            id: '10000',
            icon: "el-icon-s-comment",
            label: '短信记录',
            path: '/main/sms/log',
        },
    ],

    filterArrForKey(arr, key, value) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterArrForKey(item.children, key, value)
                }
            }
            if (value.includes(item[key])) {
                return item
            } else {
                return null
            }
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    },
    filterChecked(arr, key) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterChecked(item.children)
                }
            }
            return item[key]
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    }
}

// const data2 = {
//     menuItems: [
//         {
//             id: '10',
//             icon: "el-icon-office-building",
//             label: '系统首页',
//             path: '/main/register/index',
//         },
//         // {
//         //     id: '10-1',
//         //     icon: "el-icon-office-building",
//         //     label: '职工体检',
//         //     path: '/main/enterprise',
//         //     children: [{
//         //         id: '10-1-1',
//         //         label: '炼化公司',
//         //         path: '/main/enterprise/lianhua',
//         //     }, {
//         //         id: '10-1-2',
//         //         label: '石化公司',
//         //         path: '/main/enterprise/shihua',
//         //     }]
//         // },
//         {
//             id: '10-2',
//             icon: "el-icon-office-building",
//             label: '职业套餐设置',
//             path: '/main/package/index',
//             children: [{
//                 id: '10-2-1',
//                 label: '固定套餐',
//                 path: '/main/package/guding',
//             }, {
//                 id: '10-2-2',
//                 label: '自选套餐',
//                 path: '/main/package/zixuan',
//             }]
//         },
//         {
//             id: '20',
//             icon: "el-icon-office-building",
//             label: '炼化职工体检',
//             path: '/main/enterprise/lianhua',
//             children: [{
//                 id: '20-1',
//                 label: '预约记录',
//                 path: '/main/enterprise/lianhua/appoint',
//             }, {
//                 id: '20-2',
//                 label: '体检排期',
//                 path: '/main/enterprise/lianhua/scheduling',
//             }, {
//                 id: '20-3',
//                 label: '固定套餐配置',
//                 path: '/main/enterprise/lianhua/package',
//             }, {
//                 id: '20-4',
//                 label: '自选套餐配置',
//                 path: '/main/enterprise/lianhua/package-custom',
//             }, {
//                 id: '20-5',
//                 label: '体检人员名单',
//                 path: '/main/enterprise/lianhua/staff',
//             }]
//         },
//         {
//             id: '30',
//             icon: "el-icon-office-building",
//             label: '石化职工体检',
//             path: '/main/enterprise/shihua',
//             children: [{
//                 id: '20-1',
//                 label: '预约记录',
//                 path: '/main/enterprise/shihua/appoint',
//             }, {
//                 id: '20-2',
//                 label: '体检排期',
//                 path: '/main/enterprise/shihua/scheduling',
//             }, {
//                 id: '20-3',
//                 label: '固定套餐配置',
//                 path: '/main/enterprise/shihua/package',
//             }, {
//                 id: '20-4',
//                 label: '自选套餐配置',
//                 path: '/main/enterprise/shihua/package-custom',
//             }, {
//                 id: '20-5',
//                 label: '体检人员名单',
//                 path: '/main/enterprise/shihua/staff',
//             }]
//         },
//         {
//             id: '10000',
//             icon: "el-icon-office-building",
//             label: '短信记录',
//             path: '/main/sms/log',
//         },
//     ]
// }

export default data
