<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="姓名：" prop="name">
                <el-autocomplete v-model="form.name" :fetch-suggestions="querySearchAsync" placeholder="请输入" clearable
                    style="width:100%" @select="handleSelect">
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                        <span class="sbname">{{ item.idnum }}</span>
                    </template>
                </el-autocomplete>
            </el-form-item>
            <el-form-item label="身份证号：" prop="idnum">
                <el-input v-model="form.idnum" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="体检日期：" prop="date">
                <el-date-picker v-model="form.date" type="date" placeholder="选择日期" value-format='yyyy-MM-dd'
                    style="width: 100%">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="体检时间：" prop="time">
                <el-select v-model="form.time" placeholder="请选择" style="width:100%">
                    <el-option label="08:00-09:00" value="08:00-09:00"></el-option>
                    <el-option label="09:00-10:00" value="09:00-10:00"></el-option>
                    <el-option label="10:00-11:00" value="10:00-11:00"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="体检费用：" prop="fee">
                <el-input v-model="form.fee" placeholder="请输入内容" readonly></el-input>
            </el-form-item>
            <el-form-item label="体检套餐：" v-if="form.fee" prop="package">
                <el-select v-model="form.package" placeholder="请选择">
                    <el-option :label="e.text" :value="e.text" v-for="(e, i) in current_packages" :key="i"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        title: {
            default: "添加/修改"
        },
    },
    data() {
        return {
            innerVisible: false,
            rules: {
                name: [
                    { required: true, message: '请输入', trigger: 'select' },
                ],
                idnum: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                date: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                time: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                fee: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                package: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ]
            },
            current_packages: [],
            package: {
                '700.00': [
                    { text: '34周岁以下员工体检套餐A', value: '34周岁以下员工体检套餐A', img: 'https://cdn.zhack.com/lianhua/700a.png' },
                    { text: '34周岁以下员工体检套餐B', value: '34周岁以下员工体检套餐B', img: 'https://cdn.zhack.com/lianhua/700b.png' },
                ],
                '1050.00': [
                    { text: '35岁-44周岁A套餐（心脑血管）', value: '35岁-44周岁A套餐（心脑血管）', img: 'https://cdn.zhack.com/lianhua/1050a.png' },
                    { text: '35岁-44周岁B套餐（综合套餐）', value: '35岁-44周岁B套餐（综合套餐）', img: 'https://cdn.zhack.com/lianhua/1050b.png' },
                    { text: '35岁-44周岁C套餐（呼吸肿瘤）', value: '35岁-44周岁C套餐（呼吸肿瘤）', img: 'https://cdn.zhack.com/lianhua/1050c.png' },
                    { text: '35岁-44周岁D套餐（消化内分泌）', value: '35岁-44周岁D套餐（消化内分泌）', img: 'https://cdn.zhack.com/lianhua/1050d.png' },
                ],
                '1750.00': [
                    { text: '45岁以上A套餐（心脑血管）', value: '45岁以上A套餐（心脑血管）', img: 'https://cdn.zhack.com/lianhua/1750a.png' },
                    { text: '45岁以上B套餐（综合套餐）', value: '45岁以上B套餐（综合套餐）', img: 'https://cdn.zhack.com/lianhua/1750b.png' },
                    { text: '45岁以上C套餐（呼吸肿瘤）', value: '45岁以上C套餐（呼吸肿瘤）', img: 'https://cdn.zhack.com/lianhua/1750c.png' },
                    { text: '45岁以上D套餐（消化内分泌）', value: '45岁以上D套餐（消化内分泌）', img: 'https://cdn.zhack.com/lianhua/1750d.png' },
                ],
                '2000.00': [
                    { text: '处级A套餐（肿瘤筛查）', value: '处级A套餐（肿瘤筛查）', img: 'https://cdn.zhack.com/lianhua/2000a.png' },
                    { text: '处级B套餐（综合）', value: '处级B套餐（综合）', img: 'https://cdn.zhack.com/lianhua/2000b.png' },
                    { text: '处级C套餐（男性专属）', value: '处级C套餐（男性专属）', img: 'https://cdn.zhack.com/lianhua/2000c.png' },
                    { text: '处级D套餐（心脑血管）', value: '处级D套餐（心脑血管）', img: 'https://cdn.zhack.com/lianhua/2000d.png' },
                    { text: '处级E套餐（消化）', value: '处级E套餐（消化）', img: 'https://cdn.zhack.com/lianhua/2000e.png' },
                ]
            }
        }
    },
    methods: {
        async querySearchAsync(queryString, cb) {
            const resp = await this.$http.get('/enterprise/lianhua/staff?page=1&pageSize=20&name=' + queryString)
            console.log(resp)
            const records = resp.data.records.map(e => {
                return {
                    value: e.name, idnum: e.idnum, fee: e.fee
                }
            })
            cb(records)
        },
        handleSelect(item) {
            this.form.idnum = item.idnum
            this.form.fee = parseFloat(item.fee).toFixed(2)
            this.current_packages = this.package[this.form.fee]
        },
        async onSubmit() {
            var va = false
            this.$refs.form.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let url = `/enterprise/lianhua/appoint/form`
                const resp = await this.$http.post(url, this.form)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.$message.success("提交成功！")
                    this.handleClose()
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.$emit('update')
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.name {
    text-overflow: ellipsis;
    overflow: hidden;
}

.sbname {
    font-size: 12px;
    color: #b4b4b4;
}
</style>
