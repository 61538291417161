<template>
    <div class="dialogC" v-dialogDrag>
        <div class="el-dialog" style="width: 400px;border: 1px solid #DCDFE6;">
            <div class="el-dialog__header">
                <span class="el-dialog__title">科室</span>
                <button type="button" @click="Close" class="el-message-box__headerbtn"><i
                        class="el-message-box__close el-icon-close"></i></button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    size="mini"
                    width="100%"
            >
                <el-table-column
                        label="科室名称"
                        type="index"
                        align="center"
                        width="70">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="体检项目"
                        align="center"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="money"
                        align="center"
                        label="检查结果"
                        width="">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    export default {
        props: ["id"],
        data() {
            return {
                tableData:[
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                    {
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },{
                        name:"血常规",
                        money:"22",
                        sampling:"是"
                    },
                ],
            }
        },
        methods: {
            Close() {
                this.$emit('close')
            },
        },
    };
</script>
<style scoped>
    .dialogC {
        z-index: 9000;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .dialogC /deep/ .el-form-item--mini .el-form-item__content, /deep/ .el-form-item--mini .el-form-item__label {
        line-height: 10px;
    }
</style>
