const statusFormatter = (row, column) => {
    const status = row[column.property]
    switch (status) {
        case 1:
            return "已收费"
        case 0:
            return "已预约"
        case -1:
            return "已取消"
        default:
            return status
    }
}

const data = {
    table: {
        columns: [
            { title: '姓名', field: 'name', width: '100' },
            { title: '身份证', field: 'idnum', width: '180' },
            { title: '预约日期', field: 'date', width: '100' },
            { title: '预约时间', field: 'time', width: '100' },
            { title: '套餐', field: 'package', width: '' },
            { title: '职工类型', field: 'type', width: '100' },
            { title: '体检费', field: 'fee', width: '100' },
            { title: '状态', field: 'status', width: '100', formatter: statusFormatter },
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
