<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>近7天预约趋势</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <v-chart ref="chart" class="chart" :option="option" v-loading="loading" />
    </el-card>
</template>

<script>
export default {
    name: "lianhuaYuyuequshi",
    data() {
        return {
            loading: false,
            option: {
                grid: {
                    bottom: 30
                },
                tooltip: {
                    trigger: 'item'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        smooth: true
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/enterprise/lianhua/charts/yuyuequshi')
            console.log(resp.data)
            this.option.xAxis.data = resp.data.dates
            this.option.series[0].data = resp.data.values
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 200px;
}

.title {
    text-align: center;
}
</style>
