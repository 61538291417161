<template>
    <el-dialog :visible="visible" width="90%" @close="handleClose" top="5vh" :close-on-click-modal="false" class="dialog" :title="title">
        <div style="padding: 10px 0;border-top: 1px solid #EBEEF5">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd" style="float: right;margin-bottom: 10px">创建项目</el-button>
        </div>
        <div style="width: 100%"  class="tables">
            <el-table :data="tableData" border size="mini" width="100%" height="500px"  v-loading="loading"
                :span-method="objectSpanMethod" >
                <el-table-column prop="cat" label="分类" align="center">
                </el-table-column>
                <el-table-column prop="name" label="项目名称" align="center">
                </el-table-column>
                <el-table-column prop="content" width="300" label="项目内容/解析" align="center">
                </el-table-column>
                <el-table-column prop="fee" label="价格(元)" align="center">
                </el-table-column>
                <el-table-column align="center" width="240" label="操作">
                    <template slot-scope="scope">
                        <el-button  type="warning" size="mini" plain @click="handleUpdate(scope.row)" icon="el-icon-edit">
                            修改
                        </el-button>
                        <el-button type="danger" size="mini" plain @click="handleDel(scope.row)" icon="el-icon-delete">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="tData" border :show-header="false" v-if="tData.length" :span-method="arraySpanMethod">
                <el-table-column prop="label" align="center"></el-table-column>
                <el-table-column ></el-table-column>
                <el-table-column  width="300" >
                </el-table-column>
                <el-table-column prop="fee" align="center"></el-table-column>
                <el-table-column width="240"></el-table-column>
            </el-table>
        </div>
        <div style="padding: 20px;text-align: right">
            <el-button @click="handleClose" size="small">取 消</el-button>
        </div>
        <el-dialog title="删除" :visible.sync="delmodel" width="30%" append-to-body>
            <span style="text-align: center;">你确定要删除此数据吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delmodel = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="delSubmit" size="mini">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog width="40%" title="添加/修改" :visible.sync="innerVisible" append-to-body :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules">
                <el-form-item label="分类：">
                    <el-select v-model="form.cat" filterable allow-create clearable default-first-option style="width: 100%"
                        placeholder="">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称：" prop="name">
                    <el-input v-model="form.name">
                    </el-input>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="项目内容/解析：">
                            <el-input v-model="form.content" type="textarea" :rows="4">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="价格(元)：" prop="fee">
                            <el-input v-model="form.fee" oninput="if(isNaN(value)) { value = parseFloat(value) }if(isNaN(value)) { value = '' }">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="排序：">
                            <el-input v-model.number="form.sort">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item style="padding: 20px;text-align: right">
                    <el-button type="primary" @click="onSubmit">确 定</el-button>
                    <el-button @click="innerVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        pk: {
            default: null
        },
        title: {
            default: "操作项目"
        },
    },
    data() {
        return {
            innerVisible: false,
            delmodel: false,
            tableData: [],
            loading: false,
            form: {
                "package_id": "",
                "cat": "",
                "name": "",
                "content": "",
                "fee": "",
                "sort": null
            },
            tData:[],
            options: [
                {
                    value: '一般项目',
                    label: '一般项目'
                },
                {
                    value: '化验检查',
                    label: '化验检查'
                }, {
                    value: '物理检查',
                    label: '物理检查'
                }
            ],
            rules: {
                name: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                cat: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                fee: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
            },
            xpk: null,
            rowSpanArr: [],
            position: null,
        }
    },
    methods: {
        handleUpdate(row) {
            this.form = row
            this.innerVisible = true
        },
        handleDel(row) {
            this.xpk = row.id
            this.delmodel = true
        },
        async delSubmit() {
            try {
                let url = `/enterprise/shihua/package/item/${this.xpk}`
                const resp = await this.$http.delete(url)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.delmodel = false
                    this.$message.success("删除成功！")
                    this.getData(this.pk)
                }
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleAdd() {
            this.form = {
                "package_id": "",
                "cat": "",
                "name": "",
                "content": "",
                "fee": "",
                "sort": null
            }
            this.innerVisible = true
        },
        async onSubmit() {
            var va = false
            this.$refs.form.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let url = `/enterprise/shihua/package/item`
                this.form.package_id = this.pk
                const resp = await this.$http.post(url, this.form)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.innerVisible = false
                    this.getData(this.pk)
                    this.$message.success("提交成功！")
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.$emit('update')
        },
        handleClose() {
            this.$emit('close')
        },
        async getData(pk) {
            this.loading = true
            this.tableData = []
            this.tData = []
            await this.$http.get(`/enterprise/shihua/package/item?page=1&pageSize=200&package_id=${pk}`)
                .then(resp => {
                    this.tableData = resp.data.records
                    this.getRowSpan()
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                    console.log(err)
                })
        },
        // eslint-disable-next-line no-unused-vars
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.rowSpanArr[rowIndex];
                return {
                    rowspan: _row, //行
                    colspan: 1 //列
                };
            }
        },
        getRowSpan() {
            this.rowSpanArr = [];
            var fee = 0
            this.tData = []
            this.tableData.forEach((item, index) => {
                if(!item.cat){
                    item.cat = "综合项目X"
                }
                if(item.fee){
                    fee = fee + Number(item.fee)
                }
                if (index == 0) {
                    this.rowSpanArr.push(1);
                    this.position = 0;
                } else {
                    if (this.tableData[index].cat == this.tableData[index - 1].cat) {
                        this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
                        this.rowSpanArr.push(0);
                        this.tableData[index].cat = this.tableData[index - 1].cat;
                    } else {
                        this.rowSpanArr.push(1);
                        this.position = index;
                    }
                }
            });
            if(this.tableData.length) {
                let nums = this.title.match(/\d+/g);
                if(nums){
                    nums = nums.pop()
                }
                if(fee){
                    fee = fee.toFixed(2)
                }else {
                    fee = 0.00
                }
                this.tData.push({
                    label: "应收",
                    fee: fee
                })
                this.tData.push({
                    label: "实收",
                    fee: nums
                })
            }
        },
        // eslint-disable-next-line no-unused-vars
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return [1, 3];
            }else if (columnIndex ===1) {
                return [0, 0];
            }else if (columnIndex ===2) {
                return [0, 0];
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .dialog ::v-deep .el-dialog__header {
        text-align: center;
    }

</style>
