<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="用户账号：" prop="username">
                <el-input v-model="form.username">
                </el-input>
            </el-form-item>
            <el-form-item label="用户名称：">
                <el-input v-model="form.name">
                </el-input>
            </el-form-item>
            <el-form-item label="用户密码：" prop="password" v-if="!form.id">
                <el-input v-model="form.password" show-password >
                </el-input>
            </el-form-item>
            <el-form-item label="用户角色：">
                <el-select v-model="form.roleIds" multiple placeholder="请选择" style="width: 100%">
                    <el-option
                            v-for="item in roleList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "添加/修改"
            },
        },
        data() {
            return {
                innerVisible: false,
                roleList:[],
                rules: {
                    username: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入原密码', trigger: 'blur'},
                        {min: 5, max: 132, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            getData() {
                this.dialogVisible = false
                this.$http.get(`/admin/role?page=1&pageSize=500`)
                    .then(resp => {
                        this.roleList= resp.data
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `/admin/user`
                    const resp = await this.$http.post(url, this.form)
                    if (resp.data.code != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success("提交成功！")
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
