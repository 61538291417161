const data = {
    form: [
        {title: '左臂踝脉搏波', field: 'xieZhuangQueSun]', span: '12',type:"input"},
        {title: '右臂踝脉搏波', field: 'yaTiQueSun', span: '12',type:"input"},
        {title: '动脉硬化指数', field: 'kouQingXiuFuTi', span: '12',type:"input"},
        {title: '外周阻力', field: 'qiTaYaBing', span: '12',type:"input"},
        {title: '体重指数', field: 'quChi', span: '12',type:"input"},
        {title: '心率', field: 'queShiYa', span: '12',type:"input"},
        {title: '每搏心输出量', field: 'baChuYa', span: '12',type:"input"},
        {title: '每分心输出量', field: 'yaZhouZhiLiao', span: '12',type:"input"},
        {title: '左心室收缩时', field: 'cuoHeJiXing', span: '12',type:"input"},
        {title: '大动脉顺应性', field: 'yaJieShi', span: '12',type:"input"},
        {title: '小动脉顺应性', field: 'yaJieShi', span: '12',type:"input"},
        {title: '臂腕脉搏波传', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左踝臂指数', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右踝臂指数', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左臂收缩压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左臂舒张压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左臂平均压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左臂脉压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左踝收缩压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左踝舒张压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左踝平均压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '左踝脉压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右臂收缩压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右臂舒张压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右臂平均压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右臂脉压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右踝收缩压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右踝舒张压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右踝平均压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '右踝脉压', field: 'yaJieShi', span: '12',type:"input"},
        {title: '诊断结论', field: 'yaJieShi', span: '24',type:"input"},
    ],
    table: {
        columns: [
            {title: 'id', field: 'id', width: ''},
            {title: '建档/预约时间', field: 'created', width: '150'},
            {title: '名称', field: 'name', width: ''},
            {title: '性别', field: 'gender', width: ''},
            {title: '年龄', field: 'age', width: ''},
            {title: '婚否', field: 'marriage', width: ''},
            {title: '职业', field: 'occupation', width: ''},
            {title: '身份证', field: 'idcard', width: '180'},
            {title: '单位名称', field: 'companyName', width: ''},
            {title: '联系方式', field: 'contactWay', width: '160'},
            {title: '检查科室', field: 'keshi', width: ''},
            {title: '体检项目', field: 'tijianXingmu', width: ''},
            {title: '金额', field: 'money', width: ''},
            {title: '登记人', field: 'registrant', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
