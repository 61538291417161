<template>
    <div>
        <el-dialog :visible.sync="visible" width="600px" class="dialog" :title="title" :close-on-click-modal="false"
                   :show-close="false">
            <el-row style="display: flex;align-items: center">
                <el-col :span="12">
                    <div style="display:flex;">
                        <div>
                            <span>检查ID：</span>
                            <span>1206261051</span>
                        </div>
                        <div style="padding-left: 40px">
                            <span>姓名：</span>
                            <span>张海霞</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <el-checkbox-group v-model="checkList" class="text-group">
                        <div>
                            <el-checkbox label="启用回车连续录入"></el-checkbox>
                        </div>
                        <div>
                            <el-checkbox label="未见异常"></el-checkbox>
                        </div>
                    </el-checkbox-group>
                </el-col>
            </el-row>
            <el-row style="padding-top: 20px">
                <el-form :model="form" class="demo-form-inline" label-width="110px" >
                    <div class="text-textarea" style="max-height: 400px;overflow: auto">
                        <el-row >
                            <el-col :span="item.span" v-for="(item,index) in formfield" :key="index">
                                <el-form-item :label="item.title">
                                    <el-input
                                            size="mini"
                                            placeholder=""
                                            v-if="item.type=='input'"
                                            v-model="from[item.field]">
                                    </el-input>
                                    <el-input
                                            size="mini"
                                            placeholder=""
                                            type="textarea"
                                            :rows="3"
                                            v-else
                                            v-model="from[item.field]">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <el-form-item label-width="0">
                        <el-checkbox v-model="checked" style="color: red">特别关注此科室</el-checkbox>
                        <el-button type="primary" size="mini" @click="calculatorModel=true">浏览其它科室</el-button>
                        <el-checkbox v-model="checked" style="float: right">科室小结自动汇总</el-checkbox>
                    </el-form-item>
                    <el-form-item label-width="0">
                        <div><span>收费项目：</span><span>B超（肝、胆、脾、肾）、甲状腺B超</span></div>
                        <div>
                            <el-col :span="8">
                                <span>检查医生：</span>
                                <span style="color: red">薛志一</span>
                            </el-col>
                            <el-col :span="8">
                                <el-button type="primary" size="mini" @click="huici">编辑词汇</el-button>
                                <el-button type="primary" size="mini">默认值</el-button>
                            </el-col>
                            <el-col :span="8" style="text-align: right">
                                <el-button type="" size="mini">确 认</el-button>
                                <el-button type="" size="mini" @click="handleClose">取 消</el-button>
                            </el-col>
                        </div>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-dialog>
        <cal v-if="calculatorModel" @close="calculatorModel = false"></cal>
        <hui-ci v-if="huiciModel" @close="huiciModel = false"></hui-ci>
    </div>
</template>

<script>
    import '../../../plugins/directives'
    import Cal from './cal'
    import HuiCi from './huici'
    import data from '../data'

    export default {
        components: {
            Cal,
            HuiCi
        },
        props: {
            visible: {
                default: false
            },
            title: {
                default: ""
            },
            form: {
                default: {}
            },
        },
        data() {
            return {
                calculatorModel: false,
                huiciModel: false,
                checkList: [],
                costType: [],
                from:{},
                formfield:data.form
            }
        },
        methods: {
            huici() {
                this.calculatorModel = false
                this.huiciModel = true
            },
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/cvs/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.calculatorModel = false
                this.huiciModel = false
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .title {
        display: flex;
        justify-content: space-evenly;
    }

    ::v-deep .el-dialog__header {
        padding: 5px 10px;
        border-bottom: 1px solid #DCDFE6;
    }

    ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .text-group ::v-deep .el-checkbox__label {
        font-size: 12px;
    }

    .text-group {
        float: right;
    }

    .text-textarea {
        border: 1px solid #DCDFE6;
        padding: 10px;
    }

    .el-form-item {
        margin-bottom: 0px;
    }
</style>
