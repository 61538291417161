<template>
    <div>
        <el-dialog :visible.sync="visible" width="800px" class="dialog" :title="title" :close-on-click-modal="false"
                   :show-close="false">
            <el-row>
                <div style="display:flex;">
                    <div>
                        <span>检查ID：</span>
                        <span>1206261051</span>
                    </div>
                    <div style="padding-left: 40px">
                        <span>姓名：</span>
                        <span>张海霞</span>
                    </div>
                </div>
            </el-row>
            <el-row>
                <div style="float: right">
                    <el-checkbox v-model="checked" style="color: red">特别关注此科室</el-checkbox>
                </div>
                <el-divider content-position="left">【病症及病史情况】</el-divider>
            </el-row>
            <el-row style="padding-top: 20px">
                <el-form :model="form" class="demo-form-inline" label-width="130px">
                    <div class="text-textarea">
                        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                            <el-tab-pane label="-" name="first">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.one" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-if="item.type=='input'"
                                                    v-model="from[item.field]">
                                            </el-input>
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    type="textarea"
                                                    :rows="5"
                                                    v-else
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="问诊" name="second">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.two" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="膳食摄入" name="third">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.three" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="饮食习惯" name="fourth">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.four" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="运动锻炼" name="fifth">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.five" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="吸烟情况" name="sixth">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.six" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="饮酒情况" name="seventh">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.seven" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="问诊其他情况" name="eighth">
                                <el-row style="height: 220px">
                                    <el-col :span="item.span" v-for="(item,index) in formfield.eight" :key="index">
                                        <el-form-item :label="item.title">
                                            <el-input
                                                    size="mini"
                                                    placeholder=""
                                                    v-model="from[item.field]">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <el-form-item label-width="0">
                        <div>
                            <el-col :span="8">
                                <span>检查医生：</span>
                                <span style="color: red">薛志一</span>
                            </el-col>
                            <el-col :span="8">
                                <el-button type="primary" size="mini" @click="huici">编辑词汇</el-button>
                                <el-button type="primary" size="mini">默认值</el-button>
                            </el-col>
                            <el-col :span="8" style="text-align: right">
                                <el-button type="" size="mini">确 认</el-button>
                                <el-button type="" size="mini" @click="handleClose">取 消</el-button>
                            </el-col>
                        </div>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-dialog>
        <hui-ci v-if="huiciModel" @close="huiciModel = false"></hui-ci>
    </div>
</template>

<script>
    import '../../../plugins/directives'
    import HuiCi from './huici'
    import data from '../data'

    export default {
        components: {
            HuiCi
        },
        props: {
            visible: {
                default: false
            },
            title: {
                default: ""
            },
            form: {
                default: {}
            },
        },
        data() {
            return {
                activeName: "first",
                calculatorModel: false,
                huiciModel: false,
                checkList: [],
                costType: [],
                from: {},
                formfield: data.form
            }
        },
        methods: {
            huici() {
                this.calculatorModel = false
                this.huiciModel = true
            },
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/cvs/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.calculatorModel = false
                this.huiciModel = false
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .title {
        display: flex;
        justify-content: space-evenly;
    }

    ::v-deep .el-dialog__header {
        padding: 5px 10px;
        border-bottom: 1px solid #DCDFE6;
    }

    ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .text-group ::v-deep .el-checkbox__label {
        font-size: 12px;
    }

    .text-group {
        float: right;
    }

    .text-textarea {
        border: 1px solid #DCDFE6;
        padding: 10px;
    }

    .el-form-item {
        margin-bottom: 0px;
    }
</style>
