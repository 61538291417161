<template>
    <div class="btn">
        <el-button :size="size" icon="el-icon-upload" :type="type" @click="dialogVisible = true">导入收据</el-button>
        <el-dialog title="导入数据" :visible="dialogVisible" width="40%" @close="close">
            <div style="text-align: center;min-height: 200px;position: relative;">
                <el-upload drag v-loading="loading" ref="upload-demo" :action="action" accept=".xlsx,.xls" :data="form"
                    :headers="headers" :show-file-list="false" :limit="2" :on-preview="handlePreview"
                    :on-remove="handleRemove" :on-exceed="handleExceed" :on-change="handleChange" :file-list="fileList"
                    :before-upload="beforeUpload" :on-success="handleSuccess" :on-error="handleError" :auto-upload="false">
                    <el-button v-if="(fileList.length > 0)" style="position: absolute;top: 5px;right:5px;z-index: 99999;"
                        size="mini" type="danger" icon="el-icon-delete" circle @click="btnRemove"></el-button>
                    <div v-if="(fileList.length == 0)">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </div>
                    <div v-else>
                        <svg style="width: 80px;height: 80px;margin: 40px 0 16px" t="1670150771352" class="icon"
                            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2894" width="200"
                            height="200">
                            <path
                                d="M901.851653 926.484607a48.255937 48.255937 0 0 1-14.274541 34.442195 48.844737 48.844737 0 0 1-34.457555 14.279661H170.419644a48.220097 48.220097 0 0 1-34.457555-14.279661 48.844737 48.844737 0 0 1-14.279662-34.442195V48.846068a48.317377 48.317377 0 0 1 14.279662-34.467795A48.844737 48.844737 0 0 1 170.419644 0.001331h418.800096a48.742337 48.742337 0 0 1 34.662355 14.131182l263.858857 263.910057a48.844737 48.844737 0 0 1 14.110701 34.667475z"
                                fill="#EBECF0" p-id="2895"></path>
                            <path
                                d="M901.851653 926.484607v48.767936a48.317377 48.317377 0 0 1-14.274541 34.467796 48.844737 48.844737 0 0 1-34.457555 14.279661H170.419644a48.844737 48.844737 0 0 1-48.788417-48.844737v-48.721856a48.204737 48.204737 0 0 0 14.279662 34.442195 48.844737 48.844737 0 0 0 34.457555 14.279661h682.648713a48.844737 48.844737 0 0 0 48.773056-48.783296z"
                                fill="#C1C7D0" p-id="2896"></path>
                            <path
                                d="M24.167034 536.423034h975.153932v243.849923a48.921536 48.921536 0 0 1-48.721856 48.844736H73.011771a48.342977 48.342977 0 0 1-34.467796-14.274541 48.844737 48.844737 0 0 1-14.376941-34.570195z"
                                fill="#0AC905" p-id="2897"></path>
                            <path
                                d="M121.733627 536.412794V438.856441L24.167034 536.412794z m780.118026 0l0.926719-97.556353 97.039234 97.556353z"
                                fill="#168E2D" p-id="2898"></path>
                            <path
                                d="M901.851653 312.628125v6.860791h-263.833257a48.844737 48.844737 0 0 1-48.844736-48.844737V0.001331a48.732097 48.732097 0 0 1 34.662355 14.115822l264.268456 263.751337a49.459136 49.459136 0 0 1 13.701102 34.754515z"
                                fill="#C1C7D0" p-id="2899"></path>
                            <path
                                d="M265.190721 764.037458H136.346088v-153.94284h126.335836v35.978193H187.382182v21.749732h63.590317v35.973073h-63.590317v24.268769h77.808539zM358.05716 720.532875l-25.098207 43.504583H276.065587l54.384569-80.322456-49.366976-73.620384h56.893366l21.754852 39.321549 22.589411-39.321549h56.888246L386.508963 683.725242l52.705212 80.317336H382.320809zM599.843726 668.662222h-47.682498q-3.353596-24.268768-27.612124-25.103327-27.612124 0.839679-28.446683 45.178821 0 41.845706 30.120921 41.830345 22.584291 0 25.937886-25.932766h48.522177q-8.376309 59.417523-73.625505 61.91096-78.648218-2.508797-81.151894-79.482777 4.177915-76.124061 76.96886-81.151894 69.43735 0.839679 76.96886 62.750638zM752.112328 764.037458h-128.844633v-153.94284h126.335836v35.978193H674.303789v21.749732h63.590317v35.973073H674.303789v24.268769h77.808539zM897.694219 764.037458H777.215655v-153.94284h51.036094v117.964647h69.44247z"
                                fill="#FFFFFF" p-id="2900"></path>
                        </svg>
                        <div class="el-upload__text">{{ fileList[0].name }}</div>
                    </div>
                </el-upload>
                <div>
                    <el-checkbox v-model="form.clear" @change="handleCheckboxChange">清理已有数据</el-checkbox>
                </div>
                <div slot="tip" class="el-upload__tip">请选择 Execl 文件，然后点击上传。</div>
                <!-- <div class="el-upload__tip" slot="tip">下载Excel模板</div> -->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button @click="submitUpload" type="primary" :loading="loading" :disabled="loading">确定上传</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'ButtonImportXLSX',
    props: {
        action: {
            type: String,
            default: '/api/upload'
        },
        size: {
            type: String,
            default: 'mini'
        },
        type: {
            type: String,
            default: 'default'
        }
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            form: { clear: false },
            fileList: []
        }
    },
    computed: {
        headers() {
            const token = localStorage.getItem('token')
            return {
                Authorization: `Bearer ${token}`
            }
        }
    },
    methods: {
        handleCheckboxChange() {
            if (this.form.clear) {
                this.showConfirmationDialog();
            }
        },
        showConfirmationDialog() {
            this.$confirm('选中此项会清空现有数据，确认吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 用户点击确定
                // 在这里执行相关操作
            }).catch(() => {
                // 用户点击取消
                // 在这里可以取消选择
                this.form.clear = false
            });
        },
        btnRemove(e) {
            e.stopPropagation()
            this.fileList = []
            this.loading = false
        },
        // 删除选择的文件时触发
        handleRemove(file, fileList) {
            this.fileList = []
        },
        // 点击文件时触发
        handlePreview(file) {
        },
        // 当选择文件个数超过指定 limit 时触发；这里只上传一个文件，指定了 limit="2"，也可以不指定limit，则无限制
        handleExceed(files, fileList) {
            this.$message.warning(`已选择了 ${files.length} 个文件，如需更新请先删除已选择文件`)
        },
        //当重新选择文件时，替换已选择的文件，从而实现变更要上传的文件，保证上传列表中只有一个文件；limit要大于2，否则只会触发onExceed，而不执行onChange
        handleChange(files, fileList) {
            this.fileList = [files]
        },
        //上传成功的自定义callback
        handleSuccess(resp) {
            //清空文件列表
            this.$message[resp.status](resp.msg)
            this.$nextTick(() => {
                this.fileList = []
                this.$refs['upload-demo'].clearFiles()
            })
            this.loading = false
            if (resp.status == 'success') {
                this.close()
            }
        },
        //上传失败的自定义callback
        handleError(err, file) {
            this.$nextTick(() => {
                this.fileList = []
                this.$refs['upload-demo'].clearFiles()
            })
            this.loading = false
            this.$message({
                showClose: true,
                message: file.name + ' 上传失败,' + JSON.stringify(err),
                type: 'error'
            })
        },
        //点击删除文件时触发删除确认提醒
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`)
        },
        //上传前的触发，可以在这里对文件大小，类型等检测
        beforeUpload(file) {
            if (file.size > 1024 * 1024) {
                this.$message.warning('上传的文件较大，请静心等待！')
            }
        },
        //上传
        submitUpload() {
            if (this.fileList.length === 0) {
                this.$message.warning('请选择文件')
                return
            }
            this.loading = true
            this.$refs['upload-demo'].submit()
        },
        close() {
            this.dialogVisible = false
            this.fileList = []
            this.loading = false
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.btn {
    display: inline-block;
}
</style>