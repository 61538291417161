<template>
    <zk-admin-table title="固定套餐" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch"  v-if="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="280" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleAddProject(scope.row)" icon="el-icon-plus">
                    操作项目
                </el-button>
<!--                <el-button type="text" size="mini" @click="handleView(scope.row)" icon="el-icon-view">-->
<!--                    查看-->
<!--                </el-button>-->
                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    修改
                </el-button>
                <el-button type="text" size="mini" @click="handleDel(scope.row)" icon="el-icon-delete">
                    删除
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
            <data-form-project :visible="modelName"  @close="modelName=false" :pk="pk" :title="name" @update="getData" ref="addmodel"></data-form-project>
<!--            <view-model :visible="modelView"  @close="modelView=false" ref="vmodel" :title="name"></view-model>-->
            <el-dialog
                    title="删除"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">你确定要删除此数据吗？</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">取 消</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">确 定</el-button>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
    import data from "./data";
    import SearchForm from './widgets/searchForm'
    import dataForm from './widgets/dataform'
    import dataFormProject from './widgets/dataFormProject'
    // import viewModel from './widgets/viewModel'

    export default {
        name: 'enterprise-package-xinhuadianchang-guding',
        path: '/main/enterprise/xinhuadianchang/package',
        components: {
            // viewModel,
            SearchForm,
            dataForm,
            dataFormProject,
        },
        data() {
            return {
                loading: false,
                modelName: false,
                modelView: false,
                delmodel: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {
                    "name": "",
                    "desc": "",
                    "img": "",
                    "gender": [],
                    "minAge": null,
                    "maxAge": null,
                    "sort": null
                },
                search: {},
                pk: '',
                name:""
            }
        },
        methods: {
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleView(row){
                this.name = row.name
                this.$refs.vmodel.getData(row.id)
                this.modelView = true
            },
            handleAddProject(row){
                this.name = row.name
                this.pk = row.id
                this.$refs.addmodel.getData(row.id)
                this.modelName = true
            },
            handleAdd() {
                this.form = {
                    "name": "",
                    "desc": "",
                    "img": "",
                    "gender": [],
                    "minAge": null,
                    "maxAge": null,
                    "sort": null
                }
                this.dialogVisible = true
            },
            handleDel(row) {
                this.pk = row.id
                this.delmodel = true
            },
            async delSubmit() {
                try {
                    let url = `/enterprise/xinhuadianchang/package/${this.pk}`
                    const resp = await this.$http.delete(url)
                    if (resp.data.code != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.delmodel = false
                        this.$message.success("删除成功！")
                        this.getData()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                await this.$http.get(`/enterprise/xinhuadianchang/package?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    .then(resp => {
                        this.table.data = resp.data.records
                        this.table.total = resp.data.total
                    }).catch(err => {
                        console.log(err)
                    })
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>


<style lang="scss" scoped>
    .page-main {
        flex: 1;
        min-height: 100px;
    }
</style>
