<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules">
            <el-form-item label="类别：" prop="cat">
                <el-select
                        v-model="form.cat"
                        filterable
                        allow-create
                        clearable
                        default-first-option
                        style="width: 100%"
                        placeholder="">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="项目名称：" prop="name">
                <el-input v-model="form.name">
                </el-input>
            </el-form-item>
            <el-form-item label="医生级别：" prop="doctor">
                <el-input v-model="form.doctor">
                </el-input>
            </el-form-item>
            <el-form-item label="项目意义：" prop="content">
                <el-input v-model="form.content"  type="textarea" :rows="4">
                </el-input>
            </el-form-item>
            <el-form-item label="石化价格：" prop="fee">
                <el-input v-model.number="form.fee">
                </el-input>
            </el-form-item>
            <el-form-item label="排序：">
                <el-input v-model.number="form.sort">
                </el-input>
            </el-form-item>
            <el-form-item style="padding: 20px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "添加/修改"
            },
        },
        data() {
            return {
                options:[
                    {
                        value: '必选项目',
                        label: '必选项目'
                    },
                    {
                        value: '门诊科室',
                        label: '门诊科室'
                    },
                    {
                        value: '已婚女性',
                        label: '已婚女性'
                    },
                    {
                        value: '眼科检查',
                        label: '眼科检查'
                    },
                    {
                        value: '感染性疾病检测',
                        label: '感染性疾病检测'
                    },
                    {
                        value: '常规检验',
                        label: '常规检验'
                    },
                    {
                        value: '肝功能检测',
                        label: '肝功能检测'
                    },
                    {
                        value: '肾功能检测',
                        label: '肾功能检测'
                    },
                    {
                        value: '血脂检测',
                        label: '血脂检测'
                    },
                    {
                        value: '心血管疾病检测',
                        label: '心血管疾病检测'
                    },
                    {
                        value: '内分泌疾病检测',
                        label: '内分泌疾病检测'
                    },
                    {
                        value: '心电检测',
                        label: '心电检测'
                    },
                    {
                        value: '消化系统疾病检测',
                        label: '消化系统疾病检测'
                    },
                    {
                        value: '奥林巴斯消化内镜中心',
                        label: '奥林巴斯消化内镜中心'
                    },
                    {
                        value: '飞利浦彩超检查',
                        label: '飞利浦彩超检查'
                    },
                    {
                        value: '多普勒检查',
                        label: '多普勒检查'
                    },
                    {
                        value: 'X射线',
                        label: 'X射线'
                    },
                    {
                        value: '美国GECT',
                        label: '美国GECT'
                    },
                    {
                        value: '美国GE超导核磁1.5T（铭德',
                        label: '美国GE超导核磁1.5T（铭德'
                    }, {
                        value: '核磁共振（西城）',
                        label: '核磁共振（西城）'
                    },
                    {
                        value: '优生优育',
                        label: '优生优育'
                    },
                    {
                        value: '肿瘤标志物检测',
                        label: '肿瘤标志物检测'
                    },
                    {
                        value: '风湿免疫功能检测',
                        label: '风湿免疫功能检测'
                    },
                    {
                        value: '过敏原检测',
                        label: '过敏原检测'
                    },
                    {
                        value: '维生素检测',
                        label: '维生素检测'
                    },
                    {
                        value: '微量元素及其他检测',
                        label: '微量元素及其他检测'
                    },
                    {
                        value: '骨骼健康检测',
                        label: '骨骼健康检测'
                    },
                    {
                        value: '设备检测',
                        label: '设备检测'
                    },{
                        value: '其他',
                        label: '其他'
                    }
                ],
                rules: {
                    name: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    cat: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    fee: [
                        {required: true, message: '请输入石化价格', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `enterprise/lianhua/package/custom`
                    const resp = await this.$http.post(url, this.form)
                    if (resp.data.code != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success("提交成功！")
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
