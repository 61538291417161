<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="用户账号">
                <div>{{form.username}}</div>
            </el-form-item>
            <el-form-item label="原密码" prop="password">
                <el-input v-model="form.password" show-password/>
            </el-form-item>
            <el-form-item label="新密码" prop="newpassword1">
                <el-input v-model="form.new_password" show-password/>
            </el-form-item>
            <el-form-item label="确认密码" prop="newpassword2">
                <el-input v-model="form.new_password2" show-password/>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "重置密码"
            },
        },
        data() {
            const validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入新密码'));
                } else if (value !== this.form.newpassword1) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                rules: {
                    password: [
                        {required: true, message: '请输入原密码', trigger: 'blur'},
                        {min: 5, max: 132, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ],
                    new_password: [
                        {required: true, message: '请输入新密码', trigger: 'blur'},
                        {min: 5, max: 32, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ],
                    new_password2: [
                        {required: true, validator: validatePass2, trigger: 'blur'},
                        {min: 5, max: 32, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            async onSubmit() {
                const that = this;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        that.$http.post('/admin/user/passwd', that.form)
                            .then(function (res) {
                                if (res.data.code === 200) {
                                    that.$message({type: 'success', message: '修改成功', showClose: true})
                                    that.$emit('close')
                                } else {
                                    that.$message({type: 'error', message: res.data.msg, showClose: true})
                                }
                            })
                            .catch(function (err) {
                                that.$message({type: 'error', message: err, showClose: true})
                            })
                    } else {
                        console.log('error submit!!');
                    }
                });
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
