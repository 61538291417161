<template>
    <el-dialog :visible="visible" width="60%" @close="handleClose" class="dialog" :title="title">
        <div style="padding-bottom: 20px">
            <el-card class="box-card" shadow="never">
                <div slot="header" class="clearfix">
                    <span>体检基本信息</span>
                    <span style="padding-left: 30px">档案：{{form.tjda}}</span>
                </div>
                <el-row>
                    <el-col :span="18">
                        <el-col :span="8">
                            <span>姓名：{{form.name}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>性别：{{form.gender}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>年龄：{{form.age}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>婚否：{{form.marriage}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>职业：{{form.work}}</span>
                        </el-col>
                        <el-col :span="24">
                            <span>单位名称：{{form.company}}</span>
                        </el-col>
                        <el-col :span="24">
                            <span>联系方式：{{form.phone}}</span>
                        </el-col>
                    </el-col>
                    <el-col :span="6">
                        <div style="display: flex;align-items: end">
                            <div class="border">
                                <div class="images">
                                    <img class="images" src="../../../assets/hero.svg"/>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-col :span="8">
                            <el-divider content-position="left"><span style="color:blue">【建档日期】 {{form.created}}</span></el-divider>
                        </el-col>
                        <el-col :span="8"  >
                            <el-divider content-position="left"><span style="color:red">【体检金额】</span>￥ {{form.fee}}</el-divider>
                        </el-col>
                        <el-col :span="8" style="padding-left: 20px">
                            <el-divider><span style="color:blue">{{form.id}}</span></el-divider>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="display: flex;height: 50px">
                        <div style="width: 80px">检查科室：</div>
                        <el-col  :span="22" >
                            <span style="color: #c38282"></span>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="display: flex;height: 50px">
                        <div  style="color:red;width: 80px">体检项目：</div>
                        <el-col  :span="22" >
                            <span style="color: seagreen"></span>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-col :span="12">
                            <div style="display: flex;align-items: center">
                                <div style="width: 100px;color:red">【终检】</div>
                                <el-divider content-position="left"><span style="color:blue">【体检套餐组合名称】 {{form.tijianXingmu}}</span></el-divider>
                            </div>
                        </el-col>
                        <el-col :span="12"  >
                            <el-divider content-position="left"><span style="color:red">【报告类型】 健康体检</span></el-divider>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" style="display: flex;height: 50px">
                        <div style="width: 80px">弃检项目：</div>
                        <el-col  :span="22" >
                            <span></span>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" style="display: flex;height: 50px">
                        <div style="width: 80px">未检科室：</div>
                        <el-col  :span="22" >
                            <span ></span>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" style="display: flex;height: 50px">
                        <div style="width: 80px">未检项目：</div>
                        <el-col :span="22" >
                            <span></span>
                        </el-col>
                    </el-col>
                </el-row>
            </el-card>

        </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            title: {
                default: "详情"
            },
            form: {
                default: {}
            },
        },
        data() {
            return {
                tableData:[],
                rowSpanArr: [],
                position: null,
            }
        },
        methods: {
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 0px;
    }
    .el-row {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .border {
        height: 100px;
        width: 80px;
        padding: 5px;
        border: 1px solid #EBEEF5;

        .images {
            height: 100px;
            width: 80px;
            background-color: #EBEEF5;
        }
    }

    .el-form-item {
        margin-bottom: 10px;
    }

    .el-divider--horizontal {
        margin: 9px 0;
    }

    ::v-deep .el-card__body {
        padding: 10px 20px;
    }

    ::v-deep .el-card__header {
        padding: 10px 20px;
    }

    .box-card {
        font-size: 14px;
        font-weight: 500;
    }

    .el-divider__text {
        padding: 0px;
    }
    .zhongjian ::v-deep .el-divider__text.is-left {
        left: 0px;
        transform: translateY(-50%);
    }
</style>
