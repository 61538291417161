<template>
    <zk-admin-table title="问卷调查" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <el-table-column type="index" label="序号" align="center" width="55"/>
        <el-table-column prop="name" label="姓名" width=""/>
        <el-table-column prop="idnum" label="身份证号" width=""/>
        <el-table-column prop="created" label="创建时间" width=""/>
        <el-table-column align="center" width="320" label="操作">
            <template slot-scope="scope">
                <el-button type="primary" plain size="mini" @click="handleInfo(scope.row)" icon="el-icon-edit">
                    详 情
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <view-info :data="items" :visible="dialogVisible" @close="dialogVisible=false"
                       @update="getData"></view-info>
        </template>
    </zk-admin-table>
</template>

<script>
    import viewInfo from './view'

    export default {
        name: 'enterprise-shihua-evaluate',
        path: '/main/enterprise/shihua/evaluate',
        components: {
            viewInfo,
        },
        data() {
            return {
                loading: false,
                dialogVisible: false,
                search: {},
                items: [],
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                }
            }
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleInfo(row) {
                this.dialogVisible = true
                this.items = row.data
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/enterprise/shihua/evaluate?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    this.table.data = resp.data.records
                    this.table.total = resp.data.total
                } catch (error) {
                    this.$message.error(error.message)
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    :deep(.el-table .el-table__cell) {
        padding: 6px 0;
    }
</style>
