<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="请选择" @change="doSearch" style="width:120px">
                <el-option label="已预约" :value="0"></el-option>
                <el-option label="已收费" :value="1"></el-option>
                <el-option label="已取消" :value="-1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="姓名">
            <el-input v-model.trim="form.name" clearable style="width:100px" />
        </el-form-item>
        <el-form-item label="身份证号">
            <el-input v-model.trim="form.idnum" clearable @clear="doSearch" style="width:180px" />
        </el-form-item>
        <el-form-item label="类型">
            <el-select v-model="form.type" placeholder="请选择" @change="doSearch" style="width:120px" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="普通职工" value="普通职工"></el-option>
                <el-option label="处级干部" value="处级干部"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="预约日期">
            <el-date-picker v-model="form.daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
                            value-format="yyyy-MM-dd" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
    export default {
        props: ['form'],
        data() {
            return {
                // form: {},
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>

